// @ts-ignore
import { Get as GetReactAxios } from 'react-axios'
import { ReactChild, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import FullPageLoader from './FullPageLoader'
import { Alert } from '@mui/material'

/* eslint-disable react/require-default-props */

/**
 * Wrapper around react-axios to:
 * - Add typescript
 * - Add default loader
 * - Get .data from payload
 * - Change to returned value from args to one single object.
 */

interface ChildrenProps {
    data: any
    response: any
    error: string
    isLoading: boolean
    refetch: () => void
}

interface GetProps {
    url: string
    children: (props: ChildrenProps) => ReactChild
    debounce?: number
    interval?: number
    hideLoader?: boolean
    hideError?: boolean
    disableLoader?: boolean
    disableCapture?: boolean
    defaultErrorMessage?: string
    params?: Record<string, string | number>
    forceRenderChildren?: boolean
}

const Refetcher = ({ interval, refetch }: { interval: number; refetch: () => void }) => {
    useEffect(() => {
        const intervalId = setTimeout(refetch, interval * 1000)
        return () => clearTimeout(intervalId)
    }, [refetch])
    return null
}

const Get = ({
    children,
    url,
    params = {},
    debounce = 0,
    interval = null,
    hideLoader = false,
    hideError = false,
    disableLoader = false,
    disableCapture = false,
    defaultErrorMessage = 'Something went wrong',
    forceRenderChildren,
}: GetProps) => (
    <GetReactAxios url={url} params={params} debounce={debounce}>
        {(error: any, response: any, isLoading: boolean, refetch: () => void) => {
            const data = error ? null : (response && response.data)

            if (error && !disableCapture && !forceRenderChildren) {
                return hideError ? null : (
                    <Alert severity="error" sx={{ mt: 4 }}>{defaultErrorMessage}</Alert>
                )
            }

            if (isLoading && !data && !disableLoader && !forceRenderChildren) {
                return hideLoader ? null : <FullPageLoader />
            }

            if (!data && !forceRenderChildren) {
                return null
            }

            return (
                <>
                    {interval && <Refetcher interval={interval} refetch={refetch} />}
                    {children({ data, error, response, isLoading, refetch })}
                </>
            )
        }}
    </GetReactAxios>
)

export default Get
