import { Box, Button, Drawer, Typography } from '@mui/material'
import { useState } from 'react'
import DvrIcon from '@mui/icons-material/Dvr'
import { Close } from '@mui/icons-material'
import { isNil } from 'lodash'
import { FieldDefinition, TrackerLocation } from './types'
import { format } from 'date-fns'

export const dateTimeParser = (v) => (v ? format(new Date(v), 'MMM dd - HH:mm:ss') : '-')

const locationFields: FieldDefinition[] = [
    {
        field: 'dateTime',
        label: 'Time Stamp',
        parser: dateTimeParser,
    },
    { field: 'lat', label: 'Latitude' },
    { field: 'long', label: 'Longitude' },
    { field: 'speed', label: 'Speed' },
    { field: 'alt', label: 'Altitude' },
    { field: 'accuracy', label: 'Location Accuracy' },
    { field: 'visSat', label: 'Visual Satellites', hideIfEmpty: true },
    { field: 'usedSat', label: 'Currently using Satellites', hideIfEmpty: true },
    { field: 'signalStrength', label: 'GSM Tower Signal Strength' },
    { field: 'networkConnected', label: 'GSM Network Currently Connected' },
    { field: 'networksAvailable', label: 'GSM Available Networks' },
    { field: 'wifiAvailable', label: 'Wifi Available' },
    { field: 'scannedNetworks', label: 'Scanned Networks' },

]

const deviceFields: FieldDefinition[] = [
    { field: 'imei', label: 'Device ID Number' },
    { field: 'batteryVoltage', label: 'Battery Voltage' },
    {
        field: 'batteryPercentage',
        label: 'Battery Percentage',
        parser: (value) => (isNil(value) ? '-' : `${value}%`),
    },
    { field: 'chargingStatus', label: 'Charging Status' },
    { field: 'firmwareVersion', label: 'Firmware Version' },
]

export const renderFieldValue = (value, parser?: (v: any) => string) => {
    if (parser) {
        return parser(value)
    }

    return isNil(value) ? '-' : `${value}`
}

const TrackerDetail = ({ data }: { data: TrackerLocation }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <DvrIcon onClick={() => setOpen(true)} />
            <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
                <Button
                    variant="text"
                    sx={{ width: 'fit-content', position: 'absolute', right: 0 }}
                    onClick={() => setOpen(false)}
                >
                    <Close />
                </Button>
                <Box p={4} sx={{maxWidth: "1200px"}}>
                    <Typography
                        variant="h5"
                        width="100vw"
                        maxWidth="400px"
                        sx={{ textDecoration: 'underline' }}
                        mb={2}
                    >
                        Location Data
                    </Typography>
                    {locationFields.map(
                        (field, i) =>
                            (!field.hideIfEmpty || !!data[field.field]) && (
                                <Box
                                    display="grid"
                                    gridTemplateColumns="220px 1fr"
                                    gap={2}
                                    px={2}
                                    py={1}
                                    sx={{ backgroundColor: i % 2 == 0 ? '#f5f5f5' : 'white' }}
                                >
                                    <div>{field.label}</div>
                                    <div>{renderFieldValue(data[field.field], field.parser)}</div>
                                </Box>
                            )
                    )}

                    <Typography
                        variant="h5"
                        width="100vw"
                        maxWidth="400px"
                        sx={{ textDecoration: 'underline' }}
                        mb={2}
                        mt={6}
                    >
                        Device Data
                    </Typography>
                    {deviceFields.map(
                        (field, i) =>
                            (!field.hideIfEmpty || !!data[field.field]) && (
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(2, 1fr)"
                                    gap={2}
                                    px={2}
                                    py={1}
                                    sx={{ backgroundColor: i % 2 == 0 ? '#f5f5f5' : 'white' }}
                                >
                                    <div>{field.label}</div>
                                    <div>{renderFieldValue(data[field.field], field.parser)}</div>
                                </Box>
                            )
                    )}
                </Box>
            </Drawer>
        </>
    )
}

export default TrackerDetail
