import { useEffect, useState } from 'react'

const getTime = () => new Date()

export default (ms = 1000): Date => {
    const [now, setNow] = useState(getTime())
    useEffect(() => {
        const intervalId = setInterval(
            () => setNow(getTime()),
            ms,
        )
        return () => clearInterval(intervalId)
    }, [ms, setNow])
    return now
}
