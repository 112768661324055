import useFieldApi from '@data-driven-forms/react-form-renderer/dist/cjs/use-field-api'
import { useEffect, useRef, useState } from 'react'
import Resizer from 'react-image-file-resizer'
import api from '../utils/api'
import { useDropzone } from 'react-dropzone'
import { S } from './ImageUpload'

interface FileObject {
  id: number
  file: string
}

const resizeImage = (file: File): Promise<File> =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(file, 600, 600, 'JPEG', 100, 0, (file: File) => resolve(file), 'file')
  })

const isImageType = (file: File): boolean => file.type.includes('image')

export const uploadFile = async (file): Promise<FileObject> => {
  const formData = new FormData()
  const sizedFile = isImageType(file) ? await resizeImage(file) : file
  const parts = (sizedFile?.name || '').split('.')
  const extension = parts.pop()
  const newFileName = `${parts[0].slice(0, 90)}.${extension}` // Limit filename to less than 100 characters
  const renamedFile = new File([sizedFile], newFileName, { type: sizedFile.type })
  formData.append('file', renamedFile)
  const headers = { 'Content-Type': 'multipart/form-data' }
  const resp = await api.post<FileObject>('/files/', formData, { headers })
  return resp.data
}

function replaceSizeUnit(match, p1) {
  // Convert from bytes to Mbs
  return ` ${+p1 / 1024 / 1024} Mbs`
}

const FileUploadComponent = (props) => {
  const { input, meta, label } = useFieldApi(props)
  const inputRef = useRef()
  const [files, setFiles] = useState<FileObject[]>(input.value || [])

  const onDrop = async (acceptedFiles) => {
    const uploads = await Promise.all(Array.from(acceptedFiles).map(uploadFile))
    setFiles((current) => [...current, ...uploads])
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    multiple: true,
    maxSize: 25 * 1024 * 1024,
  })

  const amountOfFiles = files.length
  // On change of files, update form state
  useEffect(() => {
    input.onChange(files)
  }, [amountOfFiles])

  const handleRemove = (file: FileObject) => {
    setFiles((current) => current.filter(({ id }) => id !== file.id))
  }

  return (
    <div>
      <label htmlFor={input.name} style={{ fontWeight: 700, fontSize: 14 }}>
        {label}
      </label>
      <br />
      <S.Dropzone {...getRootProps()} className="dropzone">
        <input {...getInputProps()} ref={inputRef} onChange={onDrop} />
        {isDragActive ? (
          <p className="muted mb-1 text-center">Drop the file here ...</p>
        ) : (
          <p className="muted text-center">Drag and Drop here or Browser files</p>
        )}
        <S.PreviewWrapper>
          {input.value && typeof input.value === 'string' ? (
            <S.Thumb
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <img src={input.value} alt="not-found" />
              <S.Link href={input.value} className="text-cut" target="_blank">
                {input.value}
              </S.Link>
              <S.RemoveButton onClick={() => handleRemove(input.value)}>Remove</S.RemoveButton>
            </S.Thumb>
          ) : (
            (input.value || []).map((file, i) => (
              <S.Thumb
                onClick={(e) => {
                  e.stopPropagation()
                }}
                key={file?.file || i}
              >
                <img
                  src={file?.file}
                  alt={file?.name}
                  style={{ objectFit: 'contain', objectPosition: 'center' }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = '/document.png'
                    currentTarget.style.width = '80px'
                  }}
                />
                <S.Link href={file?.file} className="text-cut" target="_blank">
                  {file?.file?.split?.('__')?.slice(-1)}
                </S.Link>

                <S.RemoveButton onClick={() => handleRemove(file)}>Remove</S.RemoveButton>
              </S.Thumb>
            ))
          )}
        </S.PreviewWrapper>
      </S.Dropzone>

      {fileRejections.map(({ errors }) => (
        <div>
          {errors.map((e) => (
            <small className="text-danger" key={e.code}>
              {`${e.message.replace(/ (\d+) bytes/, replaceSizeUnit)}`}
            </small>
          ))}
        </div>
      ))}

      {meta.error && (
        <p>
          <span style={{ color: 'red' }}>{meta.error}</span>
        </p>
      )}
    </div>
  )
}

export default FileUploadComponent
