import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Header from '../../components/Header'
import auth from '../../utils/auth'
import Loader from '../vehicles/components/Loader'
import { getCurrentUser } from './authSlice'

const LogoutView = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        auth.logout()
        dispatch(getCurrentUser())
    })

    return (
        <div>
            <Header center>Logging out</Header>
            <br />
            <Loader />
        </div>
    )
}

export default LogoutView
