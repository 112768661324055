import { Box, Button, Checkbox, Container, FormControlLabel, TextField, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import omit from 'lodash/omit'
import ImageUpload from '../../components/ImageUpload'
import api from '../../utils/api'
import { showNotification } from '../notifications'
import { showErrorNotification } from '../notifications/notificationsSlice'
import useRouteGuard from '../../hooks/useRouteGuard'
import { convertToDateTimeLocalString } from '../../utils/datetime'

const ContainerForm = ({data}: {data?: any}) => {
    useRouteGuard('canRegisterContainer', 'canViewContainers')

    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <Container maxWidth="md" sx={{ p: 2 }}>
            <Formik
                initialValues={{
                    containerNumber: data?.containerNumber || '',
                    seenAt: convertToDateTimeLocalString(data?.seenAt ? new Date(data.seenAt) : new Date()),
                    location: data?.location || '',
                    trailerPlate: data?.trailerPlate || '',
                    truckPlate: data?.truckPlate || '',
                    photos: data?.photos || [],
                    intelligence: data?.intelligence || '',
                    hasLinkedVehicle: !!data?.linkedVehiclePlate || false,
                    linkedVehiclePlate: data?.linkedVehiclePlate || '',
                }}
                enableReinitialize
                onSubmit={async (values) => {
                    values.photos = values.photos.map((image) => image.id)
                    values.seenAt = new Date(values.seenAt.replace(/-/g,'/').replace('T',' ')).toISOString()
                    const { error } = data ? await api.patch(`/containers/${data.id}/`, omit(values, 'hasLinkedVehicle')) : await api.post(`/containers/`, omit(values, 'hasLinkedVehicle'))
                    if (error) {
                        return dispatch(showErrorNotification(error || 'Something went wrong'))
                    }
                    dispatch(
                        showNotification({
                            type: 'success',
                            body: data? 'Updated!' : 'Registered new container',
                        })
                    )
                    data ? window.location.reload() : history.push('/containers') // Redirect to container list page.
                }}
            >
                {({ values, handleChange, handleSubmit }) => (
                    <Form>
                        <TextField
                            fullWidth
                            id="containerNumber"
                            name="containerNumber"
                            label="Container Number"
                            size="small"
                            value={values.containerNumber}
                            onChange={handleChange}
                            sx={{ mb: 2, mt: 4 }}
                            required
                        />
                        <Box mb={4}>
                            <Typography mb={0.5}>Seen at</Typography>
                            <input
                                type="datetime-local"
                                value={values.seenAt}
                                onChange={handleChange}
                                name="seenAt"
                            ></input>
                        </Box>
                        <TextField
                            fullWidth
                            id="location"
                            name="location"
                            label="Location"
                            size="small"
                            value={values.location}
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            id="trailerPlate"
                            name="trailerPlate"
                            label="Trailer Plate"
                            size="small"
                            value={values.trailerPlate}
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            id="truckPlate"
                            name="truckPlate"
                            label="Truck Plate"
                            size="small"
                            value={values.truckPlate}
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                        />
                        <ImageUpload
                            name="photos"
                            label="Images"
                            isMulti
                            placeholder="Drag and Drop here or Browser files"
                        />
                        <TextField
                            fullWidth
                            id="intelligence"
                            name="intelligence"
                            label="Intelligence"
                            size="small"
                            value={values.intelligence}
                            onChange={handleChange}
                            sx={{ mb: 2 }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="hasLinkedVehicle"
                                    checked={values.hasLinkedVehicle}
                                    onChange={handleChange}
                                />
                            }
                            label="Linked vehicle"
                            sx={{ display: 'block' }}
                        />
                        {values.hasLinkedVehicle && (
                            <TextField
                                fullWidth
                                id="linkedVehiclePlate"
                                name="linkedVehiclePlate"
                                label="Linked Vehicle Plate"
                                size="small"
                                value={values.linkedVehiclePlate}
                                onChange={handleChange}
                                sx={{ mb: 2 }}
                            />
                        )}
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleSubmit()}
                            sx={{ mt: 2 }}
                        >
                            Submit
                        </Button>
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default ContainerForm
