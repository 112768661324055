import { IconButton } from "@mui/material"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import styled from "styled-components"

const S = {
    PaginationWrapper: styled.div`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: white;
    `,
}

export interface PaginationProps {
    limit: number
    total: number
    page: number
    onPageChange: (page: number) => void
}

const NextPrevPagination = ({ limit, page, total, onPageChange }: PaginationProps) => {
    return (
        <S.PaginationWrapper>
            <IconButton
                size="large"
                disabled={page === 1}
                color="primary"
                onClick={() => onPageChange(page - 1)}
            >
                <ChevronLeftIcon />
            </IconButton>
            {`${(page - 1) * limit + 1} - ${page * limit > total ? total : page * limit}`} of {total}
            <IconButton
                size="large"
                disabled={page * limit >= total}
                color="primary"
                onClick={() => onPageChange(page + 1)}
            >
                <ChevronRightIcon />
            </IconButton>
        </S.PaginationWrapper>
    )
}

export default NextPrevPagination
