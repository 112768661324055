import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import Get from '../../../components/Get'
import { Link } from 'react-router-dom'
import { formatTimeAgo } from '../../../utils/datetime'
import { useSelector } from 'react-redux'
import { selectUser } from '../../users/authSlice'
import { SearchesResponse } from '../types'
import { Button, Stack, Typography, Box, Grid, Pagination, Autocomplete, TextField } from '@mui/material'
import api from '../../../utils/api'
import { UserType } from '../../users/types'

const S = {
    Title: styled.h4`
        font-weight: bold;
    `,
    Search: styled(Form.Control)`
        margin: 0;
        max-width: 300px;
    `,
}

const LIMIT = 10

const PreviousSearches = ({ users }: { users: UserType[] }) => {
    const user = useSelector(selectUser)
    const [page, setPage] = useState(1)
    const [allSearches, setAllSearches] = useState(false)
    const [userFilter, setUserFilter] = useState(user.id)
    const filter = {
        user: userFilter && `${userFilter}`,
        limit: LIMIT.toString(),
        offset: (LIMIT * (page - 1)).toString(),
    }
    const searchParams = new URLSearchParams(omitBy(filter, isEmpty))

    const changeSearchesType = () => {
        if (allSearches) {
            setUserFilter(user.id)
        } else {
            setUserFilter(undefined)
        }
        setAllSearches(!allSearches)
    }

    return (
        <>
            <Box display="flex" gap="40px" alignItems="center" mb={2}>
                <S.Title>{allSearches ? 'All searches' : 'My search history'}</S.Title>
                {user.isSuperuser && (
                    <Button onClick={changeSearchesType} variant="contained" size="small">
                        {allSearches ? 'Show my searches' : 'Show all searches'}
                    </Button>
                )}
            </Box>
            {allSearches && (
                <Autocomplete
                    disablePortal
                    id="user-filter"
                    options={users}
                    sx={{ width: 300, mb: 2 }}
                    value={users.find((e) => e.id == userFilter)}
                    onChange={(e, value: UserType) => {
                        setUserFilter(value?.id)
                    }}
                    isOptionEqualToValue={(option, value) => option?.id == value?.id}
                    getOptionLabel={(user: UserType) => user.email}
                    renderInput={(params) => <TextField {...params} label="User" size="small" />}
                />
            )}
            <Get
                key={searchParams.toString()}
                url={`/rdw/searches/?${searchParams}`}
                defaultErrorMessage="Not found"
            >
                {({ data }: { data: SearchesResponse }) => (
                    <>
                        <p>Total of {data.count} searches</p>
                        {data?.results?.map((item) => (
                            <Box
                                sx={{
                                    padding: '4px',
                                    borderRadius: '3px',
                                    background: 'rgba(0,0,0,0.07)',
                                    margin: '4px 0',
                                }}
                            >
                                <Grid container alignItems="center" height={{ md: '24px', xs: 'auto' }}>
                                    <Grid item xs={12} sm={4} md={2}>
                                        <Link to={`/rdw/search/${item.plate.toUpperCase()}/`}>
                                            {item.plate}
                                        </Link>
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={10}>
                                        <Stack flexDirection="row">
                                            {item.make && (
                                                <Typography marginRight={4} variant="overline">
                                                    Make: <strong>{item.make}</strong>
                                                </Typography>
                                            )}
                                            {item.model && (
                                                <Typography variant="overline">
                                                    Model: <strong>{item.model}</strong>
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>
                                {allSearches && (
                                    <Typography variant="body1" color="blueviolet" mt={0.5} fontSize={14}>
                                        User: {item.user.email}
                                    </Typography>
                                )}
                                <Typography variant="caption" color="gray">
                                    {formatTimeAgo(item.dateCreated)}
                                </Typography>
                            </Box>
                        ))}
                        <Pagination
                            count={Math.ceil(data.count / LIMIT)}
                            shape="rounded"
                            onChange={(_, _page) => setPage(_page)}
                            page={page}
                            color="primary"
                            siblingCount={0}
                            sx={{ mt: 2, mb: 4 }}
                        />
                    </>
                )}
            </Get>
        </>
    )
}

const RdwPlateSearch = () => {
    const [search, setSearch] = useState('')
    const [users, setUsers] = useState([])
    const user = useSelector(selectUser)
    const history = useHistory()
    const goToSearch = (e) => {
        e.preventDefault()
        if (search.length > 0) {
            history.push(`/rdw/search/${search.toUpperCase()}/`)
        }
    }

    useEffect(() => {
        async function getUsers() {
            const resp = await api.get('/users/')
            setUsers(resp.data.results)
        }
        if (user.isSuperuser) {
            getUsers()
        }
    }, [])

    return (
        <Box sx={{ padding: { md: '20px 40px', xs: '16px 8px' } }}>
            <S.Title>Search Plate</S.Title>
            <form onSubmit={goToSearch}>
                <Stack flexDirection="row" alignItems="center" marginTop={2}>
                    <S.Search
                        placeholder="Search"
                        onChange={(e) => {
                            setSearch(e.target.value)
                        }}
                    />
                    <Button sx={{ ml: 2 }} variant="contained" type="submit">
                        Enter
                    </Button>
                </Stack>
            </form>
            <br />
            <hr />
            <br />
            <PreviousSearches users={users} />
        </Box>
    )
}

export default RdwPlateSearch
