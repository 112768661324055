import { FormGroup, TextInput } from "@patternfly/react-core";

export default (props: { label: string, value: string }) =>
        <FormGroup label={props.label} isRequired fieldId="simple-form-email-01">
            <TextInput
                isDisabled={true}
                value={props.value}
                id="simple-form-email-01"
                name="simple-form-email-01"
            />
        </FormGroup>

