import React from 'react'
import styled from 'styled-components'
import Toast from 'react-bootstrap/Toast'

import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import { closeNotification } from './notificationsSlice'
import { Notification as NotificationType } from './types'
import { RootState } from '../../store'
import { Alert, Snackbar } from '@mui/material'

const S = {
    NotificationsContainer: styled.div`
        position: fixed;
        bottom: 100px;
        left: 0;
        right: 0;
        padding: 10px;
        z-index: 9999;
    `,
    Toast: styled(Toast)`
        margin: 12px auto !important;
    `,
    Body: styled(Toast.Body)`
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 36px;
        font-size: 24px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 4px;
        margin: 0 auto;
        font-weight: bold;
    `,
    CloseIcon: styled.div`
        margin-left: auto;
        margin-right: 0;
        cursor: pointer;
    `,
}

const Notification = ({
    type,
    body,
    id,
    displayDuration = 5000,
}: NotificationType) => {
    const dispatch = useDispatch()
    const [show] = React.useState(true)
    const handleClose = () => dispatch(closeNotification(id))

    return (
        <Snackbar
            open={show}
            autoHideDuration={displayDuration}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert onClose={handleClose} severity={type || 'info'} variant="filled">
                {body}
            </Alert>
        </Snackbar>
    )
}

const NotificationsContainer = () => {
    const notifications = useSelector((state: RootState) => state.notifications.notifications)

    return notifications?.length ? (
        <S.NotificationsContainer>
            { notifications.map((props: NotificationType) => <Notification {...props} key={props.id} />)}
        </S.NotificationsContainer>
    ) : <></>
}

export default NotificationsContainer
