import { RouteComponentProps } from 'react-router-dom'
import { Button } from '@patternfly/react-core'
import styled from 'styled-components'
import Get from '../../components/Get'
import { VehicleType } from '../vehicles/types'
import SeizureReport from './SeizureReport'
import VehicleReport from './VehicleReport'
import TelematicsReport from './TelematicsReport'

const S = {
    Page: styled.div`
      margin-top: -15px;
      @media screen {
        height: 100%;
        background: #333638;
        max-width: 100vw;
        width: 100%;
        overflow-x: scroll;
      }
    `,
    Button: styled(Button)`
      bottom: 24px;
      right: 24px;
      position: fixed;
      z-index: 100;

      @media print {
        display: none
      }
    `
}

const reportTypeToComponent = {
  'seizure-report': SeizureReport,
  'vehicle-report': VehicleReport,
  'telematics-report': TelematicsReport,
}

const ReportView = (props: RouteComponentProps<{ id: string, reportType: string }>) => {
    const { reportType, id } = props.match.params
    const Report = reportTypeToComponent[reportType]
    return (
        <S.Page>
            <S.Button onClick={window.print}>
                Print / Download
            </S.Button>

            <Get url={`/vehicles/${id}/`}>
                {({ data }: { data: VehicleType }) => (
                  <Report vehicle={data} />
                )}
            </Get>
        </S.Page>
    )
}

export default ReportView
