import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Table } from 'react-bootstrap'
import config from '../styles'
import { formatDate } from '../../../utils/datetime'
import { VehicleType } from '../../vehicles/types'

const S = {
    Logo: styled.img`
      margin: auto;
      display: block;
      height: 50px;
      margin-bottom: 40px;
    `,
    Heading: styled.div`
      margin-bottom: 40px;
    `,
    Split: styled.div`
      display: flex;
    `,
    Col: styled.div`
      flex-grow: 1;
    `,
    TBody: styled.tbody`
      * td {
        width: 25%;
      }
    `,
    FirstCol: styled.td`
      color: ${config.colors.primary};
      font-weight: bold;
      padding-bottom: 16px !important;
    `,
    Header: styled.h1<{ primary?: boolean, fontSize?: number }>`
      max-width: 350px;
      height: 80px;
      word-wrap: anywhere;
      color: ${({ primary }) => (primary ? config.colors.primary : config.colors.gray)};
      font-weight: bold;
      font-size: ${({ fontSize }) => fontSize ?? config.fontSizes.default}px;
    `,
}

export default ({ fileName, vehicle }: { fileName: string, vehicle: VehicleType }) => {
    const today = formatDate(new Date())
    return (
        <S.Heading>
            <Helmet>
                <title>
                    {fileName}
                    {' '}
                    -
                    {' '}
                    {vehicle.plate}
                    {' '}
                    - DIGITPOL
                </title>
            </Helmet>

            <S.Logo src="/logo.png" alt="" />
            <Table size="sm" borderless>
                <S.TBody>
                    {/* Empty row to align all other rows */}
                    <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <S.Header primary fontSize={config.fontSizes.large}>{fileName}</S.Header>
                        </td>
                        <td colSpan={2}>
                            <S.Header>{vehicle.clientName}</S.Header>
                        </td>
                    </tr>
                    <tr>
                        <S.FirstCol>Date</S.FirstCol>
                        <td colSpan={3}>{today}</td>
                    </tr>
                    <tr>
                        <S.FirstCol>Our Ref</S.FirstCol>
                        <td colSpan={3}>
                            #
                            {vehicle.id}
                        </td>
                    </tr>
                    <tr>
                        <S.FirstCol>Subject</S.FirstCol>
                        <td colSpan={3}>{vehicle.plate}</td>
                    </tr>
                    <tr style={{ borderTop: '1px black solid', borderBottom: '1px black solid' }}>
                        <S.FirstCol>Ref</S.FirstCol>
                        <td colSpan={3}>
                            {vehicle.vinNumber}
                            {' '}
                            –
                            {' '}
                            {vehicle.make}
                            {' '}
                            {vehicle.model}
                        </td>
                    </tr>
                </S.TBody>
            </Table>

        </S.Heading>
    )
}
