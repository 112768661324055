import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import appSlice from './appSlice'
import authSlice from './features/users/authSlice'
import notificationSlice from './features/notifications/notificationsSlice'

const rootReducer = combineReducers({
    app: appSlice,
    auth: authSlice,
    notifications: notificationSlice,
})

const store = configureStore({
    reducer: rootReducer,
})

export type RootState = ReturnType<typeof rootReducer>;
export default store
