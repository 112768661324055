import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NewNotification, Notification } from './types'

let nextId = 0
const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: [] as Notification[],
    },
    reducers: {
        showNotification(state, action: PayloadAction<NewNotification>) {
            state.notifications.push({
                id: ++nextId,
                type: 'info',
                ...action.payload,
            })
        },
        closeNotification(state, action: PayloadAction<number>) {
            state.notifications = state.notifications.filter(({ id }) => id !== action.payload)
        },
    },
})

type ErrorType = Record<string, unknown> | string | string[]

// Transform error object to text to pass into body of notification
export const transformErrorObjectToText = (error: ErrorType) => {
    if (typeof error === 'object') {
        const fields = Object.keys(error)
        return fields.map((field) => {
            const e = error[field]
            if (typeof e === 'string') { // Example: error = {error: "not found"}
                return `${field}: ${e}`
            } if (Array.isArray(e)) { // Example: error = {code: ["Your code is invalid"]}
                return `${field}: ${e.join('. ')}`
            } if (typeof e === 'object') {
                return `${field}: ${JSON.stringify(e)}`
            }
            return e
        }).join('. ')
    }
    return error
}

export const showErrorNotification = (error: ErrorType) => notificationsSlice.actions.showNotification({
    type: 'error',
    body: transformErrorObjectToText(error),
})

export const closeNotification = notificationsSlice.actions.closeNotification
export const showNotification = notificationsSlice.actions.showNotification
export default notificationsSlice.reducer
