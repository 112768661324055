import { RDW, VehicleType } from '../types'
import { useDispatch } from 'react-redux'
import api from '../../../utils/api'
import { showErrorNotification, showNotification } from '../../notifications/notificationsSlice'
import * as Sentry from '@sentry/react'
import styled from 'styled-components'
import FormTemplate from '@data-driven-forms/pf4-component-mapper/dist/cjs/form-template'
import { componentMapper, validatorMapper } from '../formConfig'
import FormRender from '@data-driven-forms/react-form-renderer/dist/cjs/form-renderer'
import { Modal } from 'react-bootstrap'
import { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import NoteView from '../../rdw/components/NoteView'
import Get from '../../../components/Get'

const S = {
    Title: styled.h4`
        font-size: 16px;
        font-weight: bold;
    `,
}

const schema = {
    fields: [{ component: 'textarea', label: 'text', name: 'text', isRequired: true, rows: 10 }],
}

const InternalNotes = ({ vehicleId }: { vehicleId: string }) => {
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false)

    const onFormSubmit = async (_data: VehicleType['internalNotes'], refetch?: () => void) => {
        const response = await api.post<any>(`/vehicles/${vehicleId}/internal-notes/`, _data)
        if (response.code > 300) {
            dispatch(showErrorNotification(response.error || 'Something went wrong'))
        } else {
            dispatch(showNotification({ body: 'Created' }))
            await refetch()
            setShowModal(false)
        }
    }

    return (
        <Box sx={{ minHeight: { lg: 380 } }}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
                sx={{ borderBottom: '1px solid black' }}
            >
                <Typography variant="h6" fontWeight={700} sx={{ ml: 1 }}>
                    Internal notes
                </Typography>
                <Button
                    size="small"
                    variant="contained"
                    onClick={() => setShowModal(true)}
                    style={{ margin: 6 }}
                >
                    New Note
                </Button>
            </Box>

            <Get url={`/vehicles/${vehicleId}/internal-note-list/`}>
                {({ data, refetch }: { data: any; refetch: () => void }) => (
                    <Box sx={{ maxHeight: 320, overflow: 'auto', marginBottom: '16px' }}>
                        {!data.length && 'No entries'}
                        {data.map((note) => (
                            <NoteView note={note} key={note.id} />
                        ))}
                        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" className="mt-5">
                            <Modal.Body>
                                <S.Title>Create new internal note</S.Title>
                                <br />
                                <br />
                                <FormRender
                                    schema={schema}
                                    onSubmit={(data: VehicleType['internalNotes']) =>
                                        onFormSubmit(data, refetch)
                                    }
                                    onCancel={() => setShowModal(false)}
                                    FormTemplate={FormTemplate}
                                    validatorMapper={validatorMapper}
                                    componentMapper={componentMapper}
                                />
                            </Modal.Body>
                        </Modal>
                    </Box>
                )}
            </Get>
        </Box>
    )
}

export default Sentry.withErrorBoundary(InternalNotes, { fallback: <p className="error"></p> })
