import {
    clearAuthTokens,
    getAccessToken,
    setAuthTokens,
    useAuthTokenInterceptor,
    refreshTokenIfNeeded,
} from './jwt'
import { axios, getPlainAxios } from './api'

// These request should not be send with our axios client that has
// the token interceptor enabled.
const noAuthAxios = getPlainAxios()

export interface Jwt {
    readonly refresh: string
    readonly access: string
    readonly token: string
}

export interface LoginInput {
    email: string
    password: string
    token?: string
}

const storeTokens = (jwt: Jwt) => setAuthTokens({
    accessToken: jwt.access,
    refreshToken: jwt.refresh,
})

const requestRefresh = async (refresh: string): Promise<string> => {
    const resp = await noAuthAxios.post<Jwt>('/jwt/refresh/', { refresh })
    return resp.data.access
}

const login = async (params: LoginInput) => {
    const response = await noAuthAxios.post<Jwt>('/jwt/obtain/', params)
    if (response.data) { storeTokens(response.data) }
    return response
}

// eslint thinks this is a react hook
// eslint-disable-next-line
useAuthTokenInterceptor(axios, { requestRefresh })

export default {
    getAccessToken,
    storeTokens,
    refreshToken: () => () => refreshTokenIfNeeded(requestRefresh),
    login,
    logout: clearAuthTokens,
}
