import Get from '../../../components/Get'
import styled from 'styled-components'
import {Link, RouteComponentProps, useHistory} from 'react-router-dom'
import { Alert, Box, Fab } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'
import RDWTables from "../components/RDWTables";
import getStolenHistory from "../../vehicles/getStolenHistory";

const S = {
    Td: styled.td`
        width: 50%;
        font-size: 13px;
    `,
    Page: styled.div`
        margin-top: 16px;
        justify-content: center;
        padding: 0 40px;
        @media screen and (max-width: 800px) {
            padding: 0px 8px;
        }
    `,
    PageTitle: styled.h1`
        flex: 1;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
    `,
}

const StolenAlert = (props) => {
    if (props.isLoading) {
        return null
    }
    if (!props?.data?.status) {
        return (
            <Alert variant="standard" severity="info">
                SMVIU status: Not reported
            </Alert>
        )
    }
    const stolenHistory = getStolenHistory(props?.data || {});
    if (props?.data?.status === 'stolen') {
        return (
            <Alert variant="standard" severity="error">
                SMVIU status: STOLEN
                <br/>
                {stolenHistory}
            </Alert>
        )
    }
    if (stolenHistory) {
        return (
            <Alert variant="standard" severity="warning">
                SMVIU status: Previously stolen
                <br/>
                {stolenHistory}
            </Alert>
        )
    }
    return (
        <Alert variant="standard" severity="info">
            SMVIU status: Not stolen
        </Alert>
    )
}

const RdwDetails = (props: RouteComponentProps<{ plate: string }>) => {
    const history = useHistory()
    const plate = (props.match.params.plate || '-').toUpperCase()
    return (
        <S.Page>
            <Get url={`/rdw/${plate}/${history.location.search}`} defaultErrorMessage="Plate not found">
                {(props) => (
                    <>
                        <Box display="flex" mb={2}>
                            <Link to="/rdw/plate-search/">
                                <Fab variant="extended" size="small" color="primary">
                                    <ChevronLeft />
                                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>Back</Box>
                                </Fab>
                            </Link>
                            <S.PageTitle>License Plate Check</S.PageTitle>
                        </Box>
                        <Get url={`/vehicles/search-by-plate/${plate}/?limit=${1}`} forceRenderChildren>
                            {(props) => <StolenAlert {...props}/>}
                        </Get>
                        <br/>
                        <RDWTables {...props} />
                    </>
                )}
            </Get>
        </S.Page>
    )
}
export default RdwDetails
