import styled from 'styled-components'
import { Typography, Box, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material'
import * as Sentry from '@sentry/react'
import { formatDateTime } from '../../../utils/datetime'
import { HistoryEntry } from '../../vehicles/types'
import { fieldToLabel } from '../formConfig'

const S = {
    Header: styled.h1`
        margin: 16px 0;
        margin-top: 32px;
        padding-bottom: 4px;
        border-bottom: 1px solid black;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    `,
}

const UpdateLog = ({ logs = [] }: { logs: HistoryEntry[] }) => {
    return (
        <>
            <S.Header>Update Log</S.Header>
            <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow sx={{ '& > th': { fontSize: '13px', fontWeight: 700 } }}>
                            <TableCell>Field</TableCell>
                            <TableCell>Previous Value</TableCell>
                            <TableCell>New Value</TableCell>
                            <TableCell>Changed on</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {logs
                            .filter((v) => v.fieldName !== 'rdw' && v.fieldName !== 'suspendNotification')
                            .map((entry, i) => {
                                return (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            background: i % 2 == 0 ? '#f5f5f5' : 'white',
                                            '& > td': { fontSize: '13px' },
                                        }}
                                    >
                                        <TableCell>{fieldToLabel(entry.fieldName)}</TableCell>
                                        <TableCell>{entry.previousValue}</TableCell>
                                        <TableCell>{entry.newValue}</TableCell>
                                        <TableCell>{formatDateTime(entry.datetime)}</TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
                {!logs.length && <Typography sx={{ ml: 2, my: 2 }}>No entries</Typography>}
            </Box>
        </>
    )
}

export default Sentry.withErrorBoundary(UpdateLog, { fallback: <p className="error"></p> })
