import styled from 'styled-components'
import Header from '../../../components/Header'
import { VehicleType } from '../../vehicles/types'

const S = {
    ImageSection: styled.div`
      display: flex;
      flex-direction: column;
      min-height: 450px;
      justify-content: center;
    `,
    ImageWrapper: styled.div`
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
    `,
    Image: styled.img`
        min-width: 0;
        width: 100%;
        max-height: 360px;
    `,
}

export default ({
    vehicle, header, imageField
}: { vehicle: VehicleType, header?: string, imageField?: string }) => (
    <S.ImageSection>
        <Header>{(vehicle[imageField || 'images']?.length) ? (header || 'Vehicle Images') : ''}</Header>
        <S.ImageWrapper>
            { vehicle[imageField || 'images'].map(({ file }) => (
                <S.Image src={file} alt="" />
            )) }
        </S.ImageWrapper>
    </S.ImageSection>
)
