import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import {
    Button,
    Stack,
    Container,
    Alert,
    Divider,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Pagination,
    IconButton,
    Collapse,
    Box,
    Typography,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import api from '../../utils/api'
import FullPageLoader from '../../components/FullPageLoader'
import { Link } from 'react-router-dom'
import { formatDateTime } from '../../utils/datetime'
import { DetailTable } from '../vin-search/VinSearchItem'
import useRouteGuard from '../../hooks/useRouteGuard'

const S = {
    Search: styled(Form.Control)`
        margin: 0;
        max-width: 300px;
    `,
}

const RESULTS_PER_PAGE = 25

const ExpandableRow = ({ item }) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <TableRow key={item.id}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>{formatDateTime(item.dateCreated)}</TableCell>
                <TableCell>{item.vin}</TableCell>
                <TableCell>{item.make}</TableCell>
                <TableCell>{item.model}</TableCell>
                <TableCell>{item.documents}</TableCell>
                <TableCell>{item.primaryDamage}</TableCell>
                <TableCell>{item.estimatedRetailValue}</TableCell>
                <TableCell>{item.estimatedRepairCost}</TableCell>
                <TableCell>
                    {item.vehicle ? <Link to={`/vehicles/${item.vehicle}/`}>{item.vin}</Link> : 'No case'}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                Detail
                            </Typography>

                            <DetailTable item={item} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

const DataTable = ({ data = [] }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Created Date</TableCell>
                        <TableCell>VIN</TableCell>
                        <TableCell>Make</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>Documents</TableCell>
                        <TableCell>Primary Damage</TableCell>
                        <TableCell>Estimated Retail Value</TableCell>
                        <TableCell>Estimated Repair Cost</TableCell>
                        <TableCell>Linked SMVIU Vehicle</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item) => (
                        <ExpandableRow item={item} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const WebHITS = () => {
    useRouteGuard(undefined, 'canViewWebhits')
    const [page, setPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [search, setSearch] = useState('')
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        loadData()
    }, [page])

    async function loadData() {
        setIsLoading(true)
        const resp = await api.get(
            `/webcare/?search=${search}&offset=${(page - 1) * RESULTS_PER_PAGE}&limit=${RESULTS_PER_PAGE}`
        )
        setError(`${!!resp.error && 'webError'}`)
        setData(resp.data?.results || [])
        setTotalCount(resp.data?.count || 0)
        setIsLoading(false)
    }

    return (
        <Container sx={{ mt: 4 }} maxWidth="xl">
            <Stack flexDirection="row" alignItems="center" my={2}>
                <S.Search
                    placeholder="Search by VIN"
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                />
                <Button disabled={!search.trim()} sx={{ ml: 2 }} variant="contained" onClick={loadData}>
                    Search
                </Button>
            </Stack>

            <Divider orientation="horizontal" sx={{ my: 4 }} />

            {data && (
                <>
                    {data.length ? (
                        <>
                            <DataTable data={data} />
                            <Pagination
                                sx={{ mt: 2, ml: 'auto', display: 'table' }}
                                count={Math.ceil(totalCount / RESULTS_PER_PAGE)}
                                shape="rounded"
                                onChange={(_, _page) => setPage(_page)}
                                page={page}
                                color="primary"
                                siblingCount={0}
                            />
                        </>
                    ) : (
                        <Alert
                            variant="outlined"
                            severity={error.includes('webError') ? 'error' : 'info'}
                            sx={{ mt: 2 }}
                        >
                            {error.includes('webError') ? 'Something went wrong!' : 'No data available!'}
                        </Alert>
                    )}
                </>
            )}

            {isLoading && <FullPageLoader />}
        </Container>
    )
}

export default WebHITS
