import { RouteComponentProps } from 'react-router-dom'
import Get from '../../../components/Get'
import { VehicleType } from '../types'
import VehicleForm from '../components/VehicleForm'
import Header from '../../../components/Header'
import useRouteGuard from '../../../hooks/useRouteGuard'

const VehicleEditView = (props: RouteComponentProps<{ id: string }>) => {
    useRouteGuard(['canEditVehicles', 'canReportRental'])
    const id = props.match.params.id
    return (
        <div className="mt-3">
            <Header center>Edit Vehicle Details</Header>
            <Get url={`/vehicles/${id}/`}>
                {({ data }: { data: VehicleType }) => <VehicleForm vehicle={data} />}
            </Get>
        </div>
    )
}

export default VehicleEditView
