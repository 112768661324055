import TableView from "./TableView";
import {RouteComponentProps} from "react-router-dom";

const TheftsOnStreet = (props: RouteComponentProps<{ streetFilter?: string, streetName?: string }>) => (
    <TableView
        heading={`Theft for street ${props.match.params.streetName}`}
        streetFilter={props.match.params.streetFilter}
    />
)

export default TheftsOnStreet
