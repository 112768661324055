import {RDW} from "../../vehicles/types";
import {useDispatch} from "react-redux";
import api from "../../../utils/api";
import {showErrorNotification, showNotification} from "../../notifications/notificationsSlice";
import * as Sentry from "@sentry/react";
import NoteView from "./NoteView";
import styled from "styled-components";
import FormTemplate from "@data-driven-forms/pf4-component-mapper/dist/cjs/form-template";
import {componentMapper, validatorMapper} from "../../vehicles/formConfig";
import FormRender from "@data-driven-forms/react-form-renderer/dist/cjs/form-renderer";
import {Modal} from "react-bootstrap";
import {useState} from "react";
import { Box, Button, Typography } from "@mui/material";

const S = {
    Title: styled.h4`
      font-size: 16px;
      font-weight: bold;
    `,
}

const schema = {
    fields: [
        { component: 'textarea', label: 'text', name: 'text', isRequired: true, rows: 10 },
        {
            component: 'file-upload',
            label: 'Images',
            name: 'images',
            multiple: true,
        },
        {
            component: 'file-upload',
            label: 'Files',
            name: 'files',
            multiple: true,
        },
    ],
}

const Notes = ({ data = {} as any, refetch }: { data: RDW, refetch: () => void }) => {
    const plate = data.kenteken
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false)

    const onFormSubmit = async (_data: any) => {
        const getIds = (files = []) => files.map(({ id }) => id)
        const data = {
            ..._data,
            images: getIds(_data.images  || []),
            files: getIds(_data.files  || []),
        }
        const response = await api.post<any>(`/rdw/${plate}/notes/`, data)
        if (response.code > 300) {
            dispatch(showErrorNotification(response.error || 'Something went wrong'))
        } else {
            dispatch(showNotification({ body: 'Created' }));
            await refetch();
            setShowModal(false);
        }
    }

    return (
        <Box sx={{ minHeight: {lg: 380} }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} sx={{borderBottom: '1px solid black'}}>
                <Typography variant="h6" fontWeight={700} sx={{ ml: 1 }}>
                    Intelligence
                </Typography>
                <Button size="small" variant="contained" onClick={() => setShowModal(true)} style={{ margin: 6 }}>
                    New Entry
                </Button>
            </Box>
            
            <Box sx={{ maxHeight: 320, overflow: 'auto', marginBottom: '16px' }}>
                {!data.notes.length && 'No entries'}
                {data.notes.map(note => (
                    <NoteView note={note} key={note.id} />
                ))}
            </Box>
            
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" className="mt-5">
                <Modal.Body>
                    <S.Title>Create new intelligence entry</S.Title>
                    <br/>
                    <br/>
                    <FormRender
                        schema={schema}
                        onSubmit={onFormSubmit}
                        onCancel={() => setShowModal(false)}
                        FormTemplate={FormTemplate}
                        validatorMapper={validatorMapper}
                        componentMapper={componentMapper}
                    />
                </Modal.Body>
            </Modal>
        </Box>
    )
}

export default Sentry.withErrorBoundary(Notes, { fallback: <p className='error'></p> })
