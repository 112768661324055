import React, { useEffect, useState } from 'react'
import api from '../../utils/api'
import { Link, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { FieldDefinition } from '../trackers/types'
import { renderFieldValue } from '../trackers/TrackerDetail'
import { Alert, Box, Button, Container, Divider, Grid, Paper, Typography } from '@mui/material'
import FullPageLoader from '../../components/FullPageLoader'
import ImageLink from '../../components/ImageLink'
import GroupFields from '../../components/GroupFields'
import { rdwGroupFields } from '../rdw/fields'
import VehicleInfo from '../vehicles/components/VehicleInfo'
import {
    detailFields,
    isAdministrationField,
    isAssetField,
    isDetailsField,
    isKiwaCertificateFields,
    isRentalReportFields,
    isSeizureField,
    isTelematicsField,
    isVbvTrackingFields,
} from '../vehicles/fields'
import { useSelector } from 'react-redux'
import { selectUser } from '../users/authSlice'
import InternalNotes from '../vehicles/components/InternalNotes'
import { sortByKey } from '../vehicles/views/VehicleView'
import { ContainerEdit } from './ContainerEdit'
import { format } from 'date-fns'
import useRouteGuard from '../../hooks/useRouteGuard'

const containerFields: FieldDefinition[] = [
    { field: 'containerNumber', label: 'Container Number' },
    { field: 'seenAt', label: 'Seen at', parser: (v) => format(new Date(v), 'MMM dd - HH:mm') },
    { field: 'location', label: 'Location' },
    { field: 'trailerPlate', label: 'Trailer Plate' },
    { field: 'truckPlate', label: 'Truck Plate' },
    { field: 'intelligence', label: 'Intelligence' },
]

const ContainerDetail = () => {
    useRouteGuard(undefined, 'canViewContainers')
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const user = useSelector(selectUser)

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        setIsLoading(true)
        const resp = await api.get(`/containers/${id}/`)
        resp.error && setError('Something went wrong!')
        setData(resp.data || null)
        setIsLoading(false)
    }

    if (error) {
        return (
            <Container>
                <Alert variant="outlined" severity="error" sx={{ mt: 4 }}>
                    {error}
                </Alert>
            </Container>
        )
    }

    if (!data) {
        return (
            <Container>
                <Alert variant="outlined" severity="info" sx={{ mt: 4 }}>
                    No data available!
                </Alert>
            </Container>
        )
    }

    return (
        <Box p={4}>
            <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} lg={6}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Box display="flex" gap={4} alignItems="center">
                            <Typography
                                variant="h5"
                                sx={{ textDecoration: 'underline', textUnderlineOffset: 6 }}
                                mb={2}
                            >
                                Location Data
                            </Typography>
                            <ContainerEdit data={data} />
                        </Box>
                        {containerFields.map(
                            (field, i) =>
                                (!field.hideIfEmpty || !!data[field.field]) && (
                                    <Box
                                        display="grid"
                                        gridTemplateColumns="220px 1fr"
                                        gap={2}
                                        px={2}
                                        py={1}
                                        sx={{ backgroundColor: i % 2 == 0 ? '#f5f5f5' : 'white' }}
                                    >
                                        <div>{field.label}</div>
                                        <div>{renderFieldValue(data[field.field], field.parser)}</div>
                                    </Box>
                                )
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Paper sx={{ p: 2, height: '100%' }}>
                        <Typography
                            variant="h5"
                            sx={{ textDecoration: 'underline', textUnderlineOffset: 6 }}
                            mb={2}
                        >
                            Images
                        </Typography>
                        <Box display="flex" gap={4} sx={{ '& img': { maxWidth: 280 } }} flexWrap="wrap">
                            {data.photos.map(({ file }) => (
                                <ImageLink src={file} key={file} />
                            ))}
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            {(data.rdw || data.vehicle) && (
                <>
                    <Divider sx={{ my: 4 }} />
                    <Typography variant="h4" mb={1}>
                        Linked vehicle
                    </Typography>
                </>
            )}

            {data.rdw && (
                <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={12} lg={6}>
                        <Paper sx={{ p: 2, pb: 4, height: '100%' }}>
                            <Link to={`/rdw/search/${data.linkedVehiclePlate}/`}>Go to RDW detail</Link>
                            <GroupFields settings={rdwGroupFields} data={data.rdw} />
                        </Paper>
                    </Grid>
                </Grid>
            )}

            {data.vehicle && (
                <Grid container spacing={2} alignItems="stretch">
                    <Grid item xs={12} lg={6}>
                        <Paper sx={{ p: 2, height: '100%' }}>
                            <Link to={`/vehicles/${data.vehicle.id}/`}>Go to Vehicle detail</Link>

                            <VehicleInfo
                                header="Vehicle Details"
                                fieldFilter={isDetailsField}
                                vehicle={data.vehicle}
                                compact
                                fieldSorting={(a, b) => {
                                    return (
                                        // @ts-ignore
                                        detailFields.indexOf(a.key) -
                                        // @ts-ignore
                                        detailFields.indexOf(b.key)
                                    )
                                }}
                            />

                            <VehicleInfo
                                header="Files"
                                fieldFilter={isAssetField}
                                vehicle={data.vehicle}
                                compact
                            />

                            <VehicleInfo
                                header="Telematics / GPS Data"
                                fieldFilter={isTelematicsField}
                                vehicle={{
                                    ...data,
                                    ...data.kiwaCertificate,
                                    scmClass: !isEmpty(data.kiwaCertificate),
                                }}
                                compact
                                actionsRender={() => (
                                    <div>
                                        {data.hasLocation ? (
                                            <Button variant="outlined">
                                                <Link to={`/map?vehicleId=${data.id}&mode=detail`}>Map</Link>
                                            </Button>
                                        ) : null}
                                        {data.tracker && (user.canReadVehicles || user.isSuperuser) ? (
                                            <Button variant="outlined" sx={{ ml: 2 }}>
                                                <Link to={`/trackers?imei=${data.tracker}`}>Tracker</Link>
                                            </Button>
                                        ) : null}
                                    </div>
                                )}
                            />

                            {!isEmpty(data.kiwaCertificate) && (
                                <VehicleInfo
                                    header="SCM Class"
                                    fieldFilter={isKiwaCertificateFields}
                                    vehicle={{ ...data, ...data.kiwaCertificate }}
                                    compact
                                />
                            )}

                            {!isEmpty(data.vbvTrackingLog) && (
                                <VehicleInfo
                                    header="Vbv Sent Updates"
                                    fieldFilter={isVbvTrackingFields}
                                    vehicle={{ ...data, ...data.vbvTrackingLog }}
                                    compact
                                />
                            )}

                            {!isEmpty(data.rentalReport) && (
                                <VehicleInfo
                                    header="Rental Report"
                                    fieldFilter={isRentalReportFields}
                                    vehicle={{ ...data.rentalReport }}
                                    compact
                                />
                            )}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} lg={6}>
                        <Paper sx={{ p: 2, height: '100%' }}>
                            <InternalNotes vehicleId={id} />

                            <VehicleInfo
                                header="Seizure Details"
                                fieldFilter={isSeizureField}
                                vehicle={data.vehicle}
                                compact
                            />

                            <VehicleInfo
                                fieldSorting={sortByKey}
                                header="Admin Details"
                                fieldFilter={isAdministrationField}
                                vehicle={data.vehicle}
                                compact
                            />
                        </Paper>
                    </Grid>
                </Grid>
            )}

            {isLoading && <FullPageLoader />}
        </Box>
    )
}

export default ContainerDetail
