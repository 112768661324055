import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
// @ts-ignore
import { AxiosProvider } from 'react-axios'
import 'bootstrap/dist/css/bootstrap.css'
import '@patternfly/react-core/dist/styles/base.css'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from './App'
import reportWebVitals from './reportWebVitals'
import store from './store'
import { getCurrentUser } from './features/users/authSlice'
import { axios } from './utils/api'

Sentry.init({
    dsn: "https://64578524d65740b79d60f8671b36311f@o1070715.ingest.sentry.io/4504531079659520",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

store.dispatch(getCurrentUser())

ReactDOM.render(
    <Provider store={store}>
        <AxiosProvider instance={axios}>
            <App />
        </AxiosProvider>
    </Provider>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
