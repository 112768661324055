import styled from 'styled-components'
import { Form } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { Button, Stack, Box, Container, Typography, Divider, Grid, Alert, Chip } from '@mui/material'
import api from '../../utils/api'
import FullPageLoader from '../../components/FullPageLoader'
import VinSearchItem from './VinSearchItem'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../users/authSlice'
import PreviousSearches from './PreviousSearches'
import useRouteGuard from '../../hooks/useRouteGuard'

const S = {
    Title: styled.h4`
        font-size: 24px;
        font-weight: bold;
    `,
    Search: styled(Form.Control)`
        margin: 0;
        max-width: 300px;
    `,
    ResultWrapper: styled.div`
        margin: 20px 0;
        display: grid;
        grid-gap: 20px;
    `,
}

const LIMIT = 25

const VinSearch = () => {
    useRouteGuard('canSearchVin')

    const user = useSelector(selectUser)
    const [users, setUsers] = useState([])
    const [search, setSearch] = useState('')
    const [searched, setSearched] = useState(false)
    const [smviuResult, setSmviuResult] = useState([])
    const [webResult, setWebResult] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [historyKey, setHistoryKey] = useState(1)

    const searchVin = async () => {
        setIsLoading(true)
        const [smviuResp, webResp] = await Promise.all([
            api.get(`/vehicles/search-by-vin/${search}/?limit=${LIMIT}`),
            api.get(`/webcare/?search=${search}&offset=0&limit=${LIMIT}`),
            api.post(`/vehicles/vin-searches/`, { vin: search }),
        ])
        setError(`${!!smviuResp.error && 'smviuError'}${!!webResp.error && 'webError'}`)
        setSmviuResult(smviuResp.data?.results || [])
        setWebResult(webResp.data?.results || [])
        setIsLoading(false)
        setSearched(true)
        setHistoryKey(historyKey + 1)
    }

    useEffect(() => {
        async function getUsers() {
            const resp = await api.get('/users/')
            setUsers(resp.data.results)
        }
        if (user.isSuperuser) {
            getUsers()
        }
    }, [])

    return (
        <Container sx={{ mt: 2, padding: { md: '0 40px', xs: '0 8px' } }}>
            <S.Title>VIN search</S.Title>
            <Stack flexDirection="row" alignItems="center" my={2}>
                <S.Search
                    placeholder="Search"
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                />
                <Button disabled={!search.trim()} sx={{ ml: 2 }} variant="contained" onClick={searchVin}>
                    Search
                </Button>
            </Stack>

            {searched && (
                <>
                    <Typography variant="h5" mt={3}>
                        SMVIU Data
                    </Typography>
                    {smviuResult.length ? (
                        smviuResult.map((item) => (
                            <Box
                                sx={{
                                    padding: 2,
                                    pb: 3,
                                    borderRadius: '3px',
                                    background: 'rgba(0,0,0,0.07)',
                                    margin: '4px 0',
                                }}
                            >
                                <Grid container alignItems="center" height={{ md: '60px', xs: 'auto' }}>
                                    <Grid item xs={12} sm={4} md={3}>
                                        {user.canReadVehicles || user.isSuperuser ? (
                                            <Link to={`/vehicles/${item.id}`}>{item.vinNumber}</Link>
                                        ) : (
                                            item.vinNumber
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={9}>
                                        <Stack flexDirection="row">
                                            {item.make && (
                                                <Typography marginRight={4} variant="overline">
                                                    Make: <strong>{item.make}</strong>
                                                </Typography>
                                            )}
                                            {item.model && (
                                                <Typography variant="overline">
                                                    Model: <strong>{item.model}</strong>
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Grid>
                                    <Chip
                                        label={(item.status || '').replace('_', ' ')}
                                        color={item.status === 'stolen' ? 'error' : 'primary'}
                                        variant="filled"
                                        style={{ textTransform: 'capitalize' }}
                                    />
                                </Grid>
                            </Box>
                        ))
                    ) : (
                        <Alert
                            variant="outlined"
                            severity={error.includes('smviuError') ? 'error' : 'info'}
                            sx={{ mt: 2 }}
                        >
                            {error.includes('smviuError') ? 'Something went wrong!' : 'No data available!'}
                        </Alert>
                    )}

                    <Divider orientation="horizontal" sx={{ my: 3 }} />

                    <Typography variant="h5">Web Data</Typography>

                    {webResult.length ? (
                        <S.ResultWrapper>
                            {webResult.map((item) => (
                                <VinSearchItem item={item} />
                            ))}
                        </S.ResultWrapper>
                    ) : (
                        <Alert
                            variant="outlined"
                            severity={error.includes('webError') ? 'error' : 'info'}
                            sx={{ mt: 2 }}
                        >
                            {error.includes('webError') ? 'Something went wrong!' : 'No data available!'}
                        </Alert>
                    )}
                </>
            )}

            <Divider sx={{ my: 4 }} />

            <PreviousSearches users={users} key={historyKey} />

            {isLoading && <FullPageLoader />}
        </Container>
    )
}

export default VinSearch
