import VehicleForm from '../components/VehicleForm'
import Header from '../../../components/Header'
import useRouteGuard from '../../../hooks/useRouteGuard'

const fields = [
    'plate',
    'clientName',
    'make',
    'model',
    'dateProduction',
    'color',
    'status',
    'orderToRetrieve',
    'dateOfTheft',
    'theftLocationStreet',
    'theftLocationCity',
    'vinNumber',
    'sisNumber',
    'numberOfDoors',
    'numberOfWheels',
    'policeRefNumber',
    'processTelematics',
    'connectable',
    'wamInsured',
    'files',
    'theftReport',
    'note',
]

const ReportForSearchView = () => {
    useRouteGuard('canReportMissing', 'canViewReportForSearch')
    return (
        <div>
            <Header center>Report for Search</Header>
            <VehicleForm fields={fields} />
        </div>
    )
}

export default ReportForSearchView
