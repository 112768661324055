import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { pageLoadingSelector } from '../appSlice'

const FullPageLoader = () => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export const FullPageLoaderWithSlice = () => {
    const pageLoading = useSelector(pageLoadingSelector)

    return pageLoading ? <FullPageLoader /> : <></>
}

export default FullPageLoader
