import _axios, { AxiosRequestConfig } from 'axios'
import env from '../constants/env'

type AxiosFn = (url: string, data?: any, config?: AxiosRequestConfig) => any

export interface Response<T> {
    data?: T
    code?: number
    error?: string | string[] | Record<string, unknown>,
}

const withUrlCheck = (fn: AxiosFn) => (
    url: string, data?: any, config?: AxiosRequestConfig
) => {
    if (!env.debug) {
        return fn(url, data, config)
    }

    const path = url.split('?')[0]
    if (!path.endsWith('/')) {
        console.error(`Path ${path} does not end in slash. Django might return 500 for this request`)
    }

    return fn(url, data, config)
}

export const makeSafe = (fn: AxiosFn) => async <T = any>(
    url: string, data?: any, config?: AxiosRequestConfig,
): Promise<Response<T>> => {
    try {
        const resp = await fn(url, data, config)
        return { data: resp?.data, code: resp?.status }
    } catch (error) {
        if (error?.response) {
            // Request made and server responded
            return { error: error?.response?.data, code: error?.response?.status }
        } if (error?.request) {
            // The request was made but no response was received
            return { error: 'cant_reach_server' }
        }
        // Something happened in setting up the request that triggered an Error
        return { error: error.message }
    }
}

export const getPlainAxios = () => _axios.create({
    baseURL: env.apiUrl,
})

const wrap = !env.debug
    ? (fn: AxiosFn) => makeSafe(fn)
    : (fn: AxiosFn) => makeSafe(withUrlCheck(fn))

export const axios = getPlainAxios()

export default {
    post: wrap(axios.post),
    get: wrap(axios.get),
    put: wrap(axios.put),
    patch: wrap(axios.patch),
    delete: wrap(axios.delete),
}
