import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useHistory } from 'react-router-dom'
import FormRender from '@data-driven-forms/react-form-renderer/dist/cjs/form-renderer'
import FormTemplate from '@data-driven-forms/pf4-component-mapper/dist/cjs/form-template'
import componentMapper from '@data-driven-forms/pf4-component-mapper/dist/cjs/component-mapper'
import { selectIsAuthenticated, getCurrentUser } from './authSlice'
import { showErrorNotification } from '../notifications/notificationsSlice'
import auth from '../../utils/auth'
import { Divider, Paper, Typography } from '@mui/material'
import styled from 'styled-components'
import { setIsPageLoading } from '../../appSlice'

const S = {
    Logo: styled.img`
        display: block;
        margin: auto;
        width: 300px;
        user-select: none;
    `,
}

const fields = [
    {
        component: 'text-field', name: 'email', label: 'Email', type: 'email', isRequired: true
    },
    {
        component: 'text-field', name: 'password', label: 'Password', type: 'password', isRequired: true
    },
    {
        component: 'text-field', name: 'token', label: '2FA (if enabled)', type: 'token'
    },
]

const LoginView = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const isAuthenticated = useSelector(selectIsAuthenticated)

    // Redirect to home if user is logged in
    React.useEffect(() => {
        if (isAuthenticated) {
            history.push('/')
        }
    }, [isAuthenticated, history])

    const onSubmit = async (data: { email: string, password: string, token?: string }) => {
        dispatch(setIsPageLoading(true))
        try {
            await auth.login({
                email: data.email.toLowerCase(),
                password: data.password,
                token: data.token,
            })
            dispatch(getCurrentUser())
        } catch (error) {
            dispatch(showErrorNotification(error?.response?.data || 'Something went wrong'))
        } finally {
            dispatch(setIsPageLoading(false))
        }
    }

    return (
        <Paper sx={{ maxWidth: 500, margin: 'auto', mt: 4, p: 4 }} elevation={5}>
            <S.Logo src="/logo.png" alt="" />
            <Divider orientation="horizontal" sx={{ mb: 2 }} />
            <Typography variant="h4" textAlign="center" color="primary" sx={{userSelect: 'none'}}>
                Login
            </Typography>
            <br />
            <FormRender
                schema={{ fields }}
                onSubmit={onSubmit}
                FormTemplate={FormTemplate}
                componentMapper={componentMapper}
            />
        </Paper>
    )
}

export default LoginView
