import { Box, Pagination, Typography } from '@mui/material'
import { useState } from 'react'
import Get from '../../../components/Get'
import { SearchesResponse } from '../types'
import NoteView from './NoteView'

const LIMIT = 5

const PreviousSearches = ({ plate }: { plate: string }) => {
    const [page, setPage] = useState(1)
    const searchParams = `?plate=${plate}&offset=${(page - 1) * LIMIT}&limit=${LIMIT}`
    return (
        <>
            <Get url={`/rdw/searches/${searchParams}`} defaultErrorMessage="Not found">
                {({ data }: { data: SearchesResponse }) => (
                    <>
                        <Typography variant="h6" fontWeight={700} sx={{mb: 1, fontSize: '16px'}}>
                            Previous Searches <small>({data.count} searches)</small>
                        </Typography>
                        <Box sx={{ minHeight: {lg: '264px'}, maxHeight: 320, overflow: 'auto' }}>
                            {data?.results?.map((item) => (
                                <NoteView
                                    note={{ byUser: item.user, text: '', dateCreated: item.dateCreated }}
                                />
                            ))}
                        </Box>
                        <Pagination
                            count={Math.ceil(data.count / LIMIT)}
                            shape="rounded"
                            onChange={(_, _page) => setPage(_page)}
                            page={page}
                            color="primary"
                            siblingCount={3}
                            size="small"
                            sx={{ marginTop: {lg: '-4px'} }}
                        />
                    </>
                )}
            </Get>
        </>
    )
}

export default PreviousSearches
