import React, { useEffect, useState } from 'react'
import useRouteGuard from '../../hooks/useRouteGuard'
import FullPageLoader from '../../components/FullPageLoader'
import {
    Alert,
    Button,
    Container,
    Divider,
    Input,
    Pagination,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import api from '../../utils/api'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'

const RESULTS_PER_PAGE = 10

const DataTable = ({ data = [] }) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Container number</TableCell>
                        <TableCell>Seen at</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Trailer Plate</TableCell>
                        <TableCell>Truck Plate</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell>
                                <Link to={`/containers/${item.id}`}>{item.containerNumber}</Link>
                            </TableCell>
                            <TableCell>{format(new Date(item.seenAt), 'MMM dd - HH:mm')}</TableCell>
                            <TableCell>{item.location}</TableCell>
                            <TableCell>{item.trailerPlate}</TableCell>
                            <TableCell>{item.truckPlate}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const ContainerList = () => {
    useRouteGuard(undefined, 'canViewContainers')
    const [page, setPage] = useState(1)
    const [totalCount, setTotalCount] = useState(0)
    const [search, setSearch] = useState('')
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        loadData()
    }, [page])

    async function loadData() {
        setIsLoading(true)
        const resp = await api.get(
            `/containers/?search=${search}&offset=${(page - 1) * RESULTS_PER_PAGE}&limit=${RESULTS_PER_PAGE}`
        )
        setError(`${!!resp.error && 'webError'}`)
        setData(resp.data?.results || [])
        setTotalCount(resp.data?.count || 0)
        setIsLoading(false)
    }

    return (
        <Container sx={{ mt: 4 }} maxWidth="xl">
            <Stack flexDirection="row" alignItems="center" my={2}>
                <Input
                    placeholder="Search by container number"
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    sx={{ width: 320 }}
                />
                <Button disabled={!search.trim()} sx={{ ml: 2 }} variant="contained" onClick={loadData}>
                    Search
                </Button>
            </Stack>

            <Divider orientation="horizontal" sx={{ my: 4 }} />

            {data && (
                <>
                    {data.length ? (
                        <>
                            <DataTable data={data} />
                            <Pagination
                                sx={{ mt: 2, ml: 'auto', display: 'table' }}
                                count={Math.ceil(totalCount / RESULTS_PER_PAGE)}
                                shape="rounded"
                                onChange={(_, _page) => setPage(_page)}
                                page={page}
                                color="primary"
                                siblingCount={0}
                            />
                        </>
                    ) : (
                        <Alert
                            variant="outlined"
                            severity={error.includes('webError') ? 'error' : 'info'}
                            sx={{ mt: 2 }}
                        >
                            {error.includes('webError') ? 'Something went wrong!' : 'No data available!'}
                        </Alert>
                    )}
                </>
            )}

            {isLoading && <FullPageLoader />}
        </Container>
    )
}

export default ContainerList
