import { Box, Button, Dialog, IconButton } from '@mui/material'
import React, { useState } from 'react'
import ContainerForm from './ContainerForm'
import { Close } from '@mui/icons-material'

export const ContainerEdit = ({ data }) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Button onClick={() => setOpen(true)} style={{ marginBottom: 24 }} variant="contained">
                Edit
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
                <Box display="flex" justifyContent="flex-end" pr={3} pt={3}>
                    <IconButton onClick={() => setOpen(false)}>
                        <Close fontSize="large" />
                    </IconButton>
                </Box>
                <Box px={4} mt={-8}>
                    <ContainerForm data={data} />
                </Box>
            </Dialog>
        </>
    )
}
