import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import CachedIcon from '@mui/icons-material/Cached'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SpeedIcon from '@mui/icons-material/Speed'
import LoupeIcon from '@mui/icons-material/Loupe'
import SettingsIcon from '@mui/icons-material/Settings'
import { useCallback, useEffect, useState } from 'react'
import api from '../../utils/api'
import styled, { css } from 'styled-components'
import * as Sentry from '@sentry/react'
import { format } from 'date-fns'
import { Search } from '@mui/icons-material'
import useDebounce from '../../hooks/useDebounce'
import NextPrevPagination from '../../components/NextPrevPagination'
import TrackerMap from './TrackerMap'
import useRefreshSecondAgo from '../../hooks/useRefreshSecondAgo'
import { useLocation } from 'react-router-dom'
import { TrackerLocation } from './types'
import { Modal } from 'react-bootstrap'
import TrackerForm from './TrackerForm'
import TrackerDetail from './TrackerDetail'
import useRouteGuard from '../../hooks/useRouteGuard'

const S = {
    InfoBox: styled.div`
        width: 320px;
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 99;
        background: rgba(255, 255, 255, 0.8);
        padding: 8px 16px;
        border-radius: 6px;
        font-size: 14px;
    `,
    Sidebar: styled.div<{ collapse?: boolean; isSingle?: boolean }>`
        position: fixed;
        top: 70px;
        left: 6px;
        display: grid;
        grid-template-rows: ${({ collapse }) => (collapse ? '24px' : '48px 1fr 48px 20px')};
        width: 300px;
        height: ${({ collapse }) => (collapse ? '44px' : '85vh')};
        padding: 12px;
        padding-bottom: 16px;
        overflow: hidden;
        border-radius: 8px;
        background: #0e232a;

        ${({ isSingle }) =>
            isSingle &&
            css`
                grid-template-rows: 1fr 20px;
            `}

        @media (max-width: 768px) {
            width: 180px;
            & *:not(svg) {
                font-size: 11px !important;
            }
            & svg {
                font-size: 16px !important;
            }
        }
    `,
    VehicleItem: styled.div<{ isActive?: boolean }>`
        width: 100%;
        margin-bottom: 8px;
        padding: 8px;
        background: #1c586d;
        color: white;
        border-radius: 3px;
        border-left: 5px solid ${({ isActive }) => (isActive ? '#0479cf' : 'transparent')};
        user-select: none;
        cursor: pointer;
        svg {
            font-size: 16px;
        }
        position: relative;
    `,
    RefreshIndicator: styled.div<{ refreshAgo: number }>`
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        background: #1c586d;
        color: gray;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        & > div {
            width: ${({ refreshAgo }) => `${(refreshAgo / 60) * 100}%`};
            height: 24px;
            white-space: nowrap;
            background: #2288ab;
            transition: 0.5s;
        }

        & > p {
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
        }
    `,
    CollapseButton: styled.div`
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 22px;
        background: white;
        display: grid;
        place-items: center;
        cursor: pointer;
    `,
    List: styled.div`
        margin-top: 10px;
        padding-right: 4px;
        overflow: auto;
    `,
    HideHistory: styled.div`
        position: absolute;
        top: 68px;
        right: 8px;
        font-weight: bold;
        color: white;
        background: #1c586d;
        border-radius: 4px;
        padding: 4px 8px;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            background: #18779a;
        }
    `,
    EditButton: styled.div<{ top: number }>`
        position: absolute;
        top: ${({ top }) => top}px;
        right: 8px;
        cursor: pointer;
        transition: 200ms;
        svg {
            font-size: 24px;
        }
        &:hover {
            color: #1976d2;
        }
    `,
}

const LIMIT = 999

const TrackerMapView = () => {
    useRouteGuard('canReadVehicles', 'canViewTrackers')
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const imei = params.get('imei')
    const [markers, setMarkers] = useState<TrackerLocation[]>([])
    const [selectedMarkerId, setSelectedMarkerId] = useState<string | undefined>(undefined)
    const { count: refreshAgo, reset } = useRefreshSecondAgo(60)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search)
    const [collapse, setCollapse] = useState(false)
    const [showCreateForm, setShowCreateForm] = useState(false)
    const [showUpdateForm, setShowUpdateForm] = useState(false)

    useEffect(() => {
        if (imei) {
            setSearch(imei)
        }
    }, ['imei'])

    useEffect(() => {
        async function fetchData() {
            reset()

            const resp = await api.get<{ results: TrackerLocation[]; count: number }>(
                `/map-trackers/?offset=${(page - 1) * LIMIT}&limit=${LIMIT}&search=${debouncedSearch}`
            )
            if (resp.data) {
                setMarkers(resp.data.results)
                if (resp.data.results?.length) {
                    setSelectedMarkerId((id) => id || resp.data.results[0].imei)
                }
                setTotal(resp.data.count)
            }
        }

        fetchData()

        const fetchDataInterval = setInterval(() => {
            fetchData()
        }, 60 * 1000)

        return () => clearInterval(fetchDataInterval)
    }, [page, debouncedSearch])

    const handleClickVehicle = useCallback((item: TrackerLocation) => {
        setSelectedMarkerId(item.imei)
    }, [])

    const refetch = () => {
        setPage(2)
        setPage(1)
    }

    return (
        <Box width={'100vw'} height={'calc(100vh - 95px)'}>
            <TrackerMap
                markers={markers}
                selectedMarkerId={selectedMarkerId}
                handleClickVehicle={handleClickVehicle}
            />
            <S.Sidebar collapse={collapse}>
                {!collapse && (
                    <Box display="flex" alignItems="center">
                        <TextField
                            id="input-with-icon-textfield"
                            label="Search by imei or name"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                            variant="filled"
                            size="small"
                            fullWidth
                            sx={{ background: 'white' }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <IconButton sx={{ color: 'white' }} onClick={() => setShowCreateForm(true)}>
                            <LoupeIcon sx={{ fontSize: '32px' }} />
                        </IconButton>
                    </Box>
                )}

                <S.List>
                    {markers.map((item) => (
                        <S.VehicleItem
                            isActive={item.imei === selectedMarkerId}
                            onClick={() => handleClickVehicle(item)}
                            key={item.imei}
                        >
                            <Typography variant="body1">{item.name || `imei: ${item.imei}`}</Typography>

                            <Typography variant="body2" sx={{ my: 0.5 }}>
                                <LocationOnIcon className="mr-1" />
                                {item.lat != undefined && item.long != undefined
                                    ? `${item.lat.toFixed(3)} ${item.long.toFixed(3)}`
                                    : 'No location received'}
                            </Typography>

                            {item.speed != undefined && (
                                <Typography variant="body2" sx={{ my: 0.5 }}>
                                    <SpeedIcon className="mr-1" />
                                    {item.speed} km/h
                                </Typography>
                            )}

                            <Typography variant="caption" fontStyle="italic" color="#43b0f4">
                                <AccessTimeIcon style={{ marginTop: -2, marginRight: 4 }} />
                                {format(new Date(item.dateTime || item.updatedAt), 'MMM dd yyyy - HH:mm')}
                            </Typography>
                            <S.EditButton onClick={() => setShowUpdateForm(true)} top={12}>
                                <SettingsIcon />
                            </S.EditButton>
                            <S.EditButton top={48}>
                                <TrackerDetail data={item} />
                            </S.EditButton>
                        </S.VehicleItem>
                    ))}
                </S.List>
                <NextPrevPagination limit={LIMIT} page={page} onPageChange={setPage} total={total} />
                <S.CollapseButton onClick={() => setCollapse(!collapse)}>
                    <KeyboardArrowUpIcon style={{ transform: `rotate(${collapse ? 180 : 0}deg)` }} />
                </S.CollapseButton>

                <S.RefreshIndicator refreshAgo={refreshAgo}>
                    <div />
                    <p>
                        <CachedIcon style={{ color: 'gray' }} /> {refreshAgo}s ago
                    </p>
                </S.RefreshIndicator>
            </S.Sidebar>

            <Modal show={showUpdateForm} onHide={() => setShowUpdateForm(false)} size="lg" className="mt-5">
                <Modal.Header>
                    <Typography variant="h4">Update Tracker</Typography>
                </Modal.Header>
                <Modal.Body>
                    <TrackerForm
                        initialData={markers.find((e) => e.imei === selectedMarkerId)}
                        close={() => setShowUpdateForm(false)}
                        refetch={refetch}
                    />
                </Modal.Body>
            </Modal>

            <Modal show={showCreateForm} onHide={() => setShowCreateForm(false)} size="lg" className="mt-5">
                <Modal.Header>
                    <Typography variant="h4">Create new tracker</Typography>
                </Modal.Header>
                <Modal.Body>
                    <TrackerForm close={() => setShowCreateForm(false)} refetch={refetch} />
                </Modal.Body>
            </Modal>
        </Box>
    )
}

export default Sentry.withErrorBoundary(TrackerMapView, { fallback: <p className="error"></p> })
