import styled from 'styled-components'

const S = {
    ImageLink: styled.a``,
    Img: styled.img`
      max-width: 200px;
    `,
}

export default ({ src, ...props }: any) => (
    <S.ImageLink target="_blank" {...props} href={src}>
        <S.Img src={src} alt="" style={{ objectFit: 'contain' }} />
    </S.ImageLink>
)
