import * as jwt from 'jsonwebtoken'
import { AxiosInstance, AxiosRequestConfig } from 'axios'

// a little time before expiration to try refresh (seconds)
const EXPIRE_FUDGE = 10

type Token = string
export interface IAuthTokens {
    accessToken: Token
    refreshToken: Token
}

const getTokenStorageKey = (): string => `auth-tokens-${process.env.NODE_ENV}`

export const setAuthTokens = (tokens: IAuthTokens) => (
    localStorage.setItem(getTokenStorageKey(), JSON.stringify(tokens))
)

export const clearAuthTokens = () => localStorage.removeItem(getTokenStorageKey())

const getAuthTokens = (): IAuthTokens | undefined => {
    const tokensRaw = localStorage.getItem(getTokenStorageKey())
    if (!tokensRaw) { return }

    try {
        // parse stored tokens JSON
        return JSON.parse(tokensRaw)
    } catch (err) {
        console.error('Failed to parse auth tokens: ', tokensRaw, err)
    }
}

export const setAccessToken = (token: Token) => {
    const tokens = getAuthTokens()
    if (!tokens) {
        console.warn('Trying to set new access token but no auth tokens found in storage. This should not happen.')
        return
    }

    tokens.accessToken = token
    setAuthTokens(tokens)
}

export const getRefreshToken = (): Token | undefined => {
    const refresh = getAuthTokens()?.refreshToken
    if (refresh && !isTokenExpired(refresh)) {
        return refresh
    }
}

export const getAccessToken = (): Token | undefined => {
    const tokens = getAuthTokens()
    return tokens ? tokens.accessToken : undefined
}

// gets unix TS
const getTokenExpiresTimeStamp = (token: Token): number | undefined => {
    const decoded = jwt.decode(token)
    if (!decoded) return
    return (decoded as { [key: string]: number }).exp
}

const getExpiresInFromJWT = (token: Token): number => {
    const exp = getTokenExpiresTimeStamp(token)
    if (exp) return exp - Date.now() / 1000
    return -1
}

export const isTokenExpired = (token: Token): boolean => {
    if (!token) return true
    const expin = getExpiresInFromJWT(token) - EXPIRE_FUDGE
    return !expin || expin < 0
}

const refreshToken = async (requestRefresh: TokenRefreshRequest): Promise<Token | undefined> => {
    const newRefreshToken = getRefreshToken()
    if (!newRefreshToken) return Promise.resolve(undefined)

    try {
        // do refresh with default axios client (we don't want our interceptor applied for refresh)
        const res = await requestRefresh(newRefreshToken)
        // save tokens
        setAccessToken(res)
        return res
    } catch (err) {
        // failed to refresh... check error type
        if (err && err.response && (err.response.status === 401 || err.response.status === 422)) {
            // got invalid token response for sure, remove saved tokens because they're invalid
            localStorage.removeItem(getTokenStorageKey())
            return Promise.reject(new Error(`Got 401 on token refresh; Resetting auth token: ${err}`))
        }
        // some other error, probably network error
        return Promise.reject(new Error(`Failed to refresh auth token: ${err}`))
    }
}

export type TokenRefreshRequest = (refreshToken: string) => Promise<Token>
export interface IAuthTokenInterceptorConfig {
    header?: string
    headerPrefix?: string
    requestRefresh: TokenRefreshRequest
}

export const refreshTokenIfNeeded = async (
    requestRefresh: TokenRefreshRequest,
): Promise<Token | undefined> => {
    // use access token (if we have it)
    let accessToken = getAccessToken()

    // check if access token is expired
    if (!accessToken || isTokenExpired(accessToken)) {
        // do refresh
        accessToken = await refreshToken(requestRefresh)
    }

    return accessToken
}

const authTokenInterceptor = ({
    header = 'Authorization',
    headerPrefix = 'JWT ',
    requestRefresh,
}: IAuthTokenInterceptorConfig) => async (requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    // we need refresh token to do any authenticated requests
    if (!getRefreshToken()) return requestConfig

    // do refresh if needed
    let accessToken
    try {
        accessToken = await refreshTokenIfNeeded(requestRefresh)
    } catch (err) {
        console.warn(err)
        return Promise.reject(
            new Error(`Unable to refresh access token for request: ${requestConfig} due to token refresh error: ${err}`),
        )
    }

    // add token to headers
    if (accessToken) requestConfig.headers[header] = `${headerPrefix}${accessToken}`
    return requestConfig
}

export const useAuthTokenInterceptor = (axios: AxiosInstance, config: IAuthTokenInterceptorConfig) => {
    if (!axios.interceptors) throw new Error(`invalid axios instance: ${axios}`)
    axios.interceptors.request.use(authTokenInterceptor(config))
}
