import { useEffect, useState } from "react"

const useRefreshSecondAgo = (num: number) => {
    const [count, setCount] = useState(0)
    useEffect(() => {
        const intervalId = setInterval(
            () => {
                if (count === num - 1) {
                    setCount(0)
                    return
                }
                setCount((val) => val + 1)
            },
            1000,
        )
        return () => clearInterval(intervalId)
    }, [])

    return {
        count,
        reset: () => setCount(0)
    }
}

export default useRefreshSecondAgo
