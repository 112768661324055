import Get from '../../../components/Get'
import { VehicleType } from '../types'
import { useState } from "react";
import FormTemplate from "@data-driven-forms/pf4-component-mapper/dist/cjs/form-template";
import { componentMapper, validatorMapper } from "../formConfig";
import FormRender from "@data-driven-forms/react-form-renderer/dist/cjs/form-renderer";
import Header from "../../../components/Header";
import { Button, FormGroup, TextInput } from "@patternfly/react-core";
import { RouteComponentProps, useHistory } from "react-router-dom";
import useRouteGuard from '../../../hooks/useRouteGuard';

interface VehicleListResponse {
    count: number;
    next: string;
    previous: string;
    results: VehicleType[];
}

const hideIfNotVbVApproved = (props, { meta, input }, formOptions) => {
    try {
        const values = formOptions?.getState?.()?.values || {}
        const hide = (values?.vbvApproval || '').includes('exclude')
        return hide ? { className: 'hide', isEnabled: false, isDisabled: true, value: undefined } : {}
    } catch (e) {
        return {}
    }
}

const createFields = (plate: string, vehicle?: VehicleType) => [
    {
        "component": "text-field",
        "name": "License Plate",
        "label": "License Plate",
        "initialValue": plate,
        isDisabled: true,
    },
    {
        "component": "text-field",
        "label": "Make",
        "name": "Make",
        "initialValue": vehicle?.make || '',
        isDisabled: !!vehicle?.make
    },
    {
        "component": "text-field",
        "label": "Model",
        "name": "Model",
        "initialValue": vehicle?.model || '',
        isDisabled: !!vehicle?.model
    },
    {
        "component": "text-field",
        "label": "VIN",
        "name": "VIN",
        "initialValue": vehicle?.vinNumber || '',
        isDisabled: !!vehicle?.vinNumber
    },
    {
        "component": "text-field",
        "label": "Production Date",
        "name": "Production Date",
        "initialValue": vehicle?.dateProduction || '',
        isDisabled: !!vehicle?.dateProduction
    },
    {
        "component": "checkbox",
        "name": "Request POA from VBV ",
        "initialValue": "Request POA from VBV ",
        "options": [
            {
                "value": "yes",
                "label": "Submit Request "
            }
        ],
        "label": "Request POA from VBV "
    },
    {
        "component": "checkbox",
        "name": "Request Vehicle Registration Papers ",
        "initialValue": "Request Vehicle Registration Papers ",
        "label": "Request Vehicle Registration Papers ",
        "options": [
            {
                "value": "yes",
                "label": "Submit Request "
            }
        ]
    },
    {
        "component": "text-field",
        "name": "Parking Fees",
        "label": "Parking Fees",
        "initialValue": "€",
    },
    {
        "component": "text-field",
        "name": "Towing Fees",
        "label": "Towing Fees",
        "initialValue": "€",
    },
    {
        "component": "text-field",
        "name": "Digitpol Release Fee",
        "label": "Digitpol Release Fee",
        "initialValue": "€",
    },
    {
        "component": "text-field",
        "name": "Digitpol Transportation Fee ",
        "label": "Digitpol Transportation Fee ",
        "initialValue": "€",
    },
    {
        "component": "text-field",
        "name": "Total Recovery Fee ",
        "label": "Total Recovery Fee ",
        "initialValue": "€",
    },
    {
        "component": "radio",
        "name": "vbvApproval",
        "label": "Case Approved by VbV ",
        "options": [
            {
                "value": "CaseISapprovedbyVbV-exclude",
                "label": "Case IS approved by VbV "
            },
            {
                "value": "CasepartiallyapprovedbyVbV",
                "label": "Case partially approved by VbV "
            },
            {
                "value": "ReleaseonlyapprovedbyVbV",
                "label": "Release only approved by VbV "
            },
            {
                "value": "TransportonlyapprovedbyVbV",
                "label": "Transport only approved by VbV "
            },
            {
                "value": "AlloperationsapprovedbyVbV-exclude",
                "label": "All operations approved by VbV "
            },
            {
                "value": "CaseNOTapprovedbyVbV",
                "label": "Case NOT approved by VbV "
            }
        ]
    },
    {
        "component": "radio",
        "name": "IF NOT approved by VbV ",
        "label": "IF NOT approved by VbV ",
        resolveProps: hideIfNotVbVApproved,
        "options": [
            {
                "value": "ABS",
                "label": "ABS"
            },
            {
                "value": "Detector",
                "label": "Detector"
            },
            {
                "value": "Brightmaven",
                "label": "Brightmaven"
            },
            {
                "value": "Betus ",
                "label": "Betus "
            },
            {
                "value": "Other ",
                "label": "Other "
            }
        ],
    },
    {
        "component": "radio",
        "name": "POA sent by Sirene / Interpol   ",
        "label": "POA sent by Sirene / Interpol   ",
        "options": [
            {
                "value": "YES",
                "label": "YES"
            },
            {
                "value": "NO",
                "label": "NO "
            }
        ]
    },
    {
        "component": "text-field",
        "name": "Location The Vehicle is Seized",
        "label": "Location The Vehicle is Seized",
        "initialValue": vehicle?.seizedLocationCity || '',
        isDisabled: !!vehicle?.seizedLocationCity
    },
    {
        "component": "text-field",
        "name": "Destination – Drop off ",
        "label": "Destination – Drop off "
    },
    {
        "component": "date-time-picker",
        "name": "Date of Collection ",
        "label": "Date of Collection"
    },
    {
        "component": "date-time-picker",
        "name": "Date of Delivered to Drop off ",
        "label": "Date of Delivered to Drop off "
    },
    {
        "component": "textarea",
        "name": "Case Notes",
        "label": "Case Notes"
    },
    {
        "component": "file-upload",
        "name": "files",
        "label": "Upload Files"
    },
    {
        "component": "file-upload",
        "name": "images",
        "label": "Upload Photos"
    },
    {
        "component": "radio",
        "label": "Case Completed",
        "name": "Case Completed",
        "options": [
            {
                "value": "YES",
                "label": "YES"
            },
            {
                "value": "NO",
                "label": "NO "
            }
        ]
    }
]

const ShowSubmitted = ({ data }: { data: any }) => {
    return (
        <div>
            { Object.keys(data).map(key => (
                <p>{key}: {data[key]}</p>
            )) }
        </div>
    )
}

const Form = ({ plate, vehicle }: { plate: string, vehicle?: VehicleType }) => {
    const [data, setData] = useState<any>(undefined);
    return data
        ? (
            <>
                <b>Received submission.<br/>Summary:</b>
                <ShowSubmitted data={data}/>
            </>
        )
        : (
        <FormRender
            schema={{ "fields": createFields(plate, vehicle) }}
            onSubmit={setData}
            FormTemplate={FormTemplate}
            validatorMapper={validatorMapper}
            componentMapper={componentMapper}
            subscription={{ values: true }}
        />
    )
}

const FillPlate = () => {
    const history = useHistory()
    const [plate, setPlate] = useState('')
    const submit = (e) => {
        e?.target?.preventDefault?.()
        history.push(`/repatriation/${plate}/`)
    }
    return (
        <form onSubmit={submit}>
            <Header center>Fill Repatriation</Header>
            <FormGroup label="License Plate " isRequired fieldId="LicensePlate">
                <TextInput
                    isRequired
                    type="text"
                    id="LicensePlate"
                    name="LicensePlate"
                    onChange={setPlate}
                />
            </FormGroup>

            <br/>

            <Button
                type='submit'
                disabled={plate.length > 5}
            >Enter</Button>
        </form>
    )
}

const RepatriationView = (props: RouteComponentProps<{ plate?: string }>) => {
    useRouteGuard(undefined, 'canViewRepatriation')
    const plate = props.match.params.plate || ''
    const searchParams = new URLSearchParams({
        search: plate,
        offset: '0',
        limit: '1',
    })
    return (
        <div style={{maxWidth: 600, margin: 'auto', padding: 24}}>
            {
                !plate
                    ? <FillPlate/>
                    : (
                        <>
                            <Get url={`/vehicles/?${searchParams.toString()}`}>
                                {({data}: { data: VehicleListResponse }) => (
                                    data.count > 0
                                        ? (
                                            <>
                                                <Header center>Existing Vehicle Repatriation</Header>
                                                <Form vehicle={data.results[0]} plate={plate} />
                                            </>
                                        )
                                        : (
                                            <>
                                                <Header center>New Vehicle Repatriation</Header>
                                                <Form plate={plate} />
                                            </>
                                        )

                                )}
                            </Get>
                        </>
                    )
            }
        </div>
    )
}

export default RepatriationView
