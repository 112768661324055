import { GroupField } from '../../components/GroupFields'

export type DataType = 'string' | 'date' | 'boolean' | 'float'

export interface Field {
    field: string
    label: string
    type: DataType
    value?: any
}

export const fields: Field[] = [
    { field: 'dateCreated', label: 'Data retrieved from RDW at', type: 'date' },
    { field: 'kenteken', label: 'License Plate', type: 'string' },
    { field: 'merk', label: 'Make', type: 'string' },
    { field: 'handelsbenaming', label: 'Model', type: 'string' },
    { field: 'datumEersteToelatingDt', label: 'Date of Production', type: 'date' },
    {
        field: 'datumEersteTenaamstellingInNederlandDt',
        label: 'Date of First Registration in NL',
        type: 'date',
    },
    { field: 'vervaldatumApkDt', label: 'APK Exp', type: 'date' },
    { field: 'wamVerzekerd', label: 'Insured Status', type: 'string' },
    // { field: 'taxiIndicator', label: 'Registered as TAXI', type: 'string' }, TODO fix this value
    { field: 'type', label: 'Body Type', type: 'string' },
    { field: 'catalogusprijs', label: 'New Price', type: 'string' },
    { field: 'brutoBpm', label: 'Bruto BPM', type: 'string' },
    { field: 'openstaandeTerugroepactieIndicator', label: 'Pending Recall Indicator', type: 'string' },
    { field: 'exportIndicator', label: 'Marked as Exported', type: 'string' },
    { field: 'aantalDeuren', label: 'Number of Doors', type: 'string' },
    { field: 'aantalWielen', label: 'Number of Wheels', type: 'string' },
    { field: 'aantalZitplaatsen', label: 'Number of Seats', type: 'string' },
    { field: 'eersteKleur', label: 'First Colour', type: 'string' },
    { field: 'tweedeKleur', label: 'Second Colour', type: 'string' },
    { field: 'inrichting', label: 'Body Model', type: 'string' },
    {
        field: 'jaarLaatsteRegistratieTellerstand',
        label: 'Jaar Laatste Registratie Tellerstand',
        type: 'string',
    },
    { field: 'uitvoering', label: 'Performance Code', type: 'string' },
    { field: 'cilinderinhoud', label: 'Engine Cubic Centimeters (CC)', type: 'string' },
    { field: 'aantalCilinders', label: 'Number of Engine Cylinders', type: 'string' },
    { field: 'lengte', label: 'Vehicle Width', type: 'string' },
    { field: 'breedte', label: 'Vehicle Length', type: 'string' },
    { field: 'wielbasis', label: 'Wheelbase', type: 'string' },
    { field: 'massaRijklaar', label: 'Mass', type: 'string' },
    { field: 'technischeMaxMassaVoertuig', label: 'Technische Max Massa Voertuig', type: 'string' },
    { field: 'maximumMassaTrekkenOngeremd', label: 'Maximum Mass Pulling Uninhibited', type: 'string' },
    { field: 'maximumMassaSamenstelling', label: 'Maximum Mass Composition', type: 'string' },
    { field: 'voertuigsoort', label: 'Vehicle Type by Registration', type: 'string' },
    { field: 'datumTenaamstelling', label: 'Datum Tenaamstelling', type: 'string' },
    { field: 'massaLedigVoertuig', label: 'MassaLedig Voertuig', type: 'string' },
    { field: 'toegestaneMaximumMassaVoertuig', label: 'Permitted Maximum Mass Vehicle', type: 'string' },
    { field: 'maximumTrekkenMassaGeremd', label: 'Maximum Mass Pulling Uninhibited', type: 'string' },
    { field: 'wachtOpKeuren', label: 'Wacht Op Keuren', type: 'string' },
    {
        field: 'afstandHartKoppelingTotAchterzijdeVoertuig',
        label: 'Afstand Hart Koppeling Tot Achterzijde Voertuig',
        type: 'string',
    },
    {
        field: 'afstandVoorzijdeVoertuigTotHartKoppeling',
        label: 'Afstand Voorzijde Voertuig Tot Hart Koppeling',
        type: 'string',
    },
    { field: 'europeseVoertuigcategorie', label: 'European Vehicle Category', type: 'string' },
    { field: 'plaatsChassisnummer', label: 'PlaatsChassis nummer', type: 'string' },
    { field: 'typegoedkeuringsnummer', label: 'Type Goedkeuringsnummer', type: 'string' },
    { field: 'variant', label: 'Variant Code', type: 'string' },
    {
        field: 'volgnummerWijzigingEuTypegoedkeuring',
        label: 'Volgnummer Wijziging EU Typegoedkeuring',
        type: 'string',
    },
    { field: 'vermogenMassarijklaar', label: 'Mass', type: 'string' },
    { field: 'aantalRolstoelplaatsen', label: 'Aantal Rolstoelplaatsen', type: 'string' },
    { field: 'maximumOndersteunendeSnelheid', label: 'Maximum Ondersteunende Snelheid', type: 'string' },
    { field: 'tellerstandoordeel', label: 'KM Status', type: 'string' },
    {
        field: 'codeToelichtingTellerstandoordeel',
        label: 'Code Explanation Meter Reading Judgement',
        type: 'string',
    },
    { field: 'tenaamstellenMogelijk', label: 'Permission to Transfer', type: 'string' },
    { field: 'zuinigheidsclassificatie', label: 'Economy Rating', type: 'string' },
    { field: 'newToBpmRatio', label: 'BPM Ratio', type: 'float' }
]

export const groupFields: GroupField[] = [
    {
        group: 'Vehicle Data',
        fields: [
            { field: 'kenteken', label: 'License Plate', type: 'string' },
            { field: 'merk', label: 'Make', type: 'string' },
            { field: 'handelsbenaming', label: 'Model', type: 'string' },
            { field: 'voertuigsoort', label: 'Vehicle Type by Registration', type: 'string' },
            { field: 'europeseVoertuigcategorie', label: 'European Vehicle Category', type: 'string' },
            { field: 'plaatsChassisnummer', label: 'PlaatsChassis nummer', type: 'string' },
            { field: 'typegoedkeuringsnummer', label: 'Type Goedkeuringsnummer', type: 'string' },
            { field: 'variant', label: 'Variant Code', type: 'string' },
            { field: 'eersteKleur', label: 'First Colour', type: 'string' },
            { field: 'tweedeKleur', label: 'Second Colour', type: 'string' },
        ],
    },
    {
        group: 'Registration Data',
        fields: [
            { field: 'status', label: 'Status', type: 'string' },
            { field: 'wamVerzekerd', label: 'Insured Status', type: 'boolean' },
            { field: 'tenaamstellenMogelijk', label: 'Permission to Transfer', type: 'string' },
            {
                field: 'datumEersteTenaamstellingInNederlandDt',
                label: 'Date of First Registration in NL',
                type: 'date',
            },
            { field: 'vervaldatumApkDt', label: 'APK Exp', type: 'date' },
            { field: 'catalogusprijs', label: 'New Price', type: 'string' },
            { field: 'brutoBpm', label: 'Bruto BPM', type: 'string' },
            {
                field: 'openstaandeTerugroepactieIndicator',
                label: 'Pending Recall Indicator',
                type: 'string',
            },
            { field: 'exportIndicator', label: 'Marked as Exported', type: 'string' },
            // { field: 'taxiIndicator', label: 'Registered as TAXI', type: 'boolean' }, // TODO fix this value
        ],
    },
    {
        group: 'Technical Data',
        fields: [
            { field: 'aantalDeuren', label: 'Number of Doors', type: 'string' },
            { field: 'aantalWielen', label: 'Number of Wheels', type: 'string' },
            { field: 'aantalZitplaatsen', label: 'Number of Seats', type: 'string' },
            { field: 'uitvoering', label: 'Performance Code', type: 'string' },
            { field: 'cilinderinhoud', label: 'Engine Cubic Centimeters (CC)', type: 'string' },
            { field: 'aantalCilinders', label: 'Number of Engine Cylinders', type: 'string' },
            { field: 'lengte', label: 'Vehicle Width', type: 'string' },
            { field: 'breedte', label: 'Vehicle Length', type: 'string' },
            { field: 'wielbasis', label: 'Wheelbase', type: 'string' },
            { field: 'massaRijklaar', label: 'Mass', type: 'string' },
            { field: 'technischeMaxMassaVoertuig', label: 'Technische Max Massa Voertuig', type: 'string' },
        ],
    },
]

// APK Expiry Date of Production Date of 1st Registration in NL
// Datum eerste toelating - Date of Production
// Vervaldatum APK - APK Expiry Date
// Datum eerste tenaamstelling in Nederland - 1st Registered in NL

export const rdwGroupFields: GroupField[] = [
    {
        group: 'Vehicle Data',
        fields: [
            { field: 'kenteken', label: 'License Plate', type: 'string' },
            { field: 'merk', label: 'Make', type: 'string' },
            { field: 'handelsbenaming', label: 'Model', type: 'string' },
            { field: 'voertuigsoort', label: 'Vehicle Type by Registration', type: 'string' },
            { field: 'europeseVoertuigcategorie', label: 'European Vehicle Category', type: 'string' },
            { field: 'typegoedkeuringsnummer', label: 'Type Goedkeuringsnummer', type: 'string' },
            { field: 'variant', label: 'Variant Code', type: 'string' },
            { field: 'eersteKleur', label: 'First Colour', type: 'string' },
            { field: 'tweedeKleur', label: 'Second Colour', type: 'string' },
        ],
    },
    {
        group: 'Registration Data',
        fields: [
            { field: 'wamVerzekerd', label: 'Insured Status', type: 'boolean' },
            { field: 'tenaamstellenMogelijk', label: 'Permission to Transfer', type: 'string' },
            { field: 'datumEersteToelatingDt', label: 'Date of Production', type: 'date' },
            {
                field: 'datumEersteTenaamstellingInNederlandDt',
                label: 'Date of First Registration in NL',
                type: 'date',
            },
            { field: 'vervaldatumApkDt', label: 'APK Exp', type: 'date' },
            { field: 'catalogusprijs', label: 'New Price', type: 'string' },
            { field: 'brutoBpm', label: 'Bruto BPM', type: 'string' },
            {
                field: 'openstaandeTerugroepactieIndicator',
                label: 'Pending Recall Indicator',
                type: 'string',
            },
            { field: 'exportIndicator', label: 'Marked as Exported', type: 'string' },
            // { field: 'taxiIndicator', label: 'Registered as TAXI', type: 'boolean' }, // TODO fix this value
        ],
    },
    {
        group: 'Technical Data',
        fields: [
            { field: 'aantalDeuren', label: 'Number of Doors', type: 'string' },
            { field: 'aantalWielen', label: 'Number of Wheels', type: 'string' },
            { field: 'aantalZitplaatsen', label: 'Number of Seats', type: 'string' },
            { field: 'uitvoering', label: 'Performance Code', type: 'string' },
            { field: 'cilinderinhoud', label: 'Engine Cubic Centimeters (CC)', type: 'string' },
            { field: 'aantalCilinders', label: 'Number of Engine Cylinders', type: 'string' },
            { field: 'lengte', label: 'Vehicle Width', type: 'string' },
            { field: 'breedte', label: 'Vehicle Length', type: 'string' },
            { field: 'wielbasis', label: 'Wheelbase', type: 'string' },
            { field: 'massaRijklaar', label: 'Mass', type: 'string' },
            { field: 'technischeMaxMassaVoertuig', label: 'Technische Max Massa Voertuig', type: 'string' },
        ],
    },
]

