import styled from 'styled-components'
import chunk from 'lodash/chunk'
import { VehicleType } from '../vehicles/types'
import { formatDate } from '../../utils/datetime'
import PdfPreview from './components/PdfPreview'
import PdfPage from './components/PdfPage'
import Heading from './components/Heading'
import VehicleImages from './components/VehicleImages'
import Header from '../../components/Header'
import VehicleInfo from '../vehicles/components/VehicleInfo'
import { isTelematicsField } from '../vehicles/fields'
import GroupFields from '../../components/GroupFields'
import Get from '../../components/Get'
import { format } from 'date-fns'

const S = {
    VehicleDetails: styled.div`
      margin-top: 60px;
      font-size: 16px;
      font-weight: 600;
    `,
    Note: styled.p`
        font-size: 18px;
    `,
    Locations: styled.div`
        font-size: 14px;
        & > div:nth-child(2n) {
            background: #f5f5f5;
        }
    `,
    LocationLine: styled.div`
        display: grid;
        align-items: center;
        grid-template-columns: 2fr 3fr;
        grid-gap: 16px;
        padding: 6px 0;
        & > p {
            min-width: 0;
        }
    `
}

const VehicleDetails = ({ vehicle }: { vehicle: VehicleType }) => (
    <S.VehicleDetails>
        <Header>
            Vehicle Details
        </Header>
        <br />
        Make:
        {' '}
        {vehicle.make || 'unkown'}
        <br />
        Model:
        {' '}
        {vehicle.model || 'unkown'}
        <br />
        VIN:
        {' '}
        {vehicle.vinNumber || 'unkown'}
        <br />
        Date of Theft Reported to Digitpol:
        {' '}
        {formatDate(vehicle.dateCreated) || 'unkown'}
        <br />
        Date of Theft:
        {' '}
        {formatDate(vehicle.dateOfTheft) || 'unkown'}
    </S.VehicleDetails>
)

interface ReportProps {
    vehicle: VehicleType
}

const TelematicsReport = ({ vehicle }: ReportProps) => {
    const telematics = !vehicle.firstLocatedAt ? '' : (
        `The vehicle was first located on the ${format(new Date(vehicle.firstLocatedDate), 'MMM dd, yyyy - HH:mm:ss') || 'unkown date'} 
         at ${vehicle.firstLocatedAt || 'unkown location'}.`
    )
    const body = `
            With reference to the vehicle bearing the Vehicle Identity Number (VIN) of
            ${vehicle.vinNumber || 'unkown vin'}. Digitpol activated, based on a request from the ${vehicle.clientName || 'client'} 
            and an active SIS warrant, a tracking system which enabled Digitpol to geolocate the 
            vehicle. ${telematics}
    `
    const containsLongNote = (vehicle.note || "").length > 600
    const vehicleInfo = (
        <VehicleInfo
            header="Telematics / GPS Data"
            vehicle={vehicle}
            fieldFilter={isTelematicsField}
            compact
        />
    )
    return (
        <PdfPreview>
            <PdfPage>
                <Heading
                    fileName="Telematics Report"
                    vehicle={vehicle}
                />
                <br />
                <p>{body}</p>
                <VehicleImages vehicle={vehicle} header="Images" imageField="screenshots" />
            </PdfPage>

            <PdfPage>
                <VehicleDetails vehicle={vehicle} />

                <br />
                {vehicle.note && <b>Intelligence</b>}
                <S.Note>{vehicle.note}</S.Note>

                <br />
                { !containsLongNote && vehicleInfo }
            </PdfPage>

            { containsLongNote && (
                <PdfPage>{vehicleInfo}</PdfPage>
            )}

            <Get url={`/vehicles/${vehicle.id}/all-locations/`} defaultErrorMessage="Not found" hideError>
                {({ data }) => {
                    const pages = chunk(data, 37)  // Limit 37 lines per page.
                    return pages.map((page, i) => (
                        <PdfPage>
                            {i === 0 ? (<Header>Historic Telematics Coordinates</Header>) : <div className="mt-4" />}
                            <S.Locations>
                                {page.map((location, i) => (
                                    <S.LocationLine>
                                        <p>Timestamp: {format(new Date(location.date), 'MMM dd, yyyy - HH:mm:ss')}</p>
                                        <p>GPS Coordinates: {location.lat} {location.long}</p>
                                    </S.LocationLine>
                                ))}
                            </S.Locations>
                        </PdfPage>
                    ))
                }}
            </Get>
        </PdfPreview>
    )
}

export default TelematicsReport
