import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'

const S = {
    Wrapper: styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
    `,
    Loader: styled(Spinner)`
        display: block;
        margin: auto;
        border-color: black;
        border-right-color: transparent;
    `
}

export default () => (
    <S.Wrapper>
        <S.Loader animation="border" role="status">
            <span className="sr-only">Loading...</span>
        </S.Loader>
    </S.Wrapper>
)
