import VehicleForm from '../components/VehicleForm'
import Header from '../../../components/Header'
import useRouteGuard from "../../../hooks/useRouteGuard";

const fields = [
    'plate',
    'seizedCountry',
    'seizedLocationStreet',
    'seizedLocationPostcode',
    'seizedLocationCity',
    'datetimeSeized',
    'seizedBy',
    'digitpolAtSeizure',
    'seizureOfficerName',
    'seizureOfficerContact',
    'discoveredWithFalsePlate',
    'discoveredWithPlate',
    'discoveredWithFalseVin',
    'discoveredWithVin',
    'images',
    'files',
    'note',
]

const ReportSeizureView = () => {
    useRouteGuard('canReportSeizure', 'canViewNewSeizure')
    return (
        <div style={{marginTop: 16}}>
            <Header center>Report Seizure</Header>
            <VehicleForm
                vehicle={{status: 'seized', digitpolAtSeizure: true}}
                fields={fields}
            />
        </div>
    )
}

export default ReportSeizureView
