import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useState } from 'react'
import Get from '../../../components/Get'
import useDebounce from '../../../hooks/useDebounce'
import Header from '../../../components/Header'
import { formatDateTime } from '../../../utils/datetime'
import {
    Pagination,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@mui/material'
import { RDW } from '../../vehicles/types'
import useRouteGuard from '../../../hooks/useRouteGuard'

interface RdwListResponse {
    count: number
    next: string
    previous: string
    results: RDW[]
}

const S = {
    TableView: styled.div`
        padding: 24px;
    `,
    SliderWrapper: styled.div`
        max-width: 100%;
        width: 330px;
        margin-left: auto;
        margin-right: 12px;
        margin-top: 24px;

        & > input {
            width: 100%;
        }
    `,
    Search: styled(Form.Control)`
        margin: 24px 0;
        max-width: 100%;
        width: 200px;

        @media (max-width: 600px) {
            width: 100%;
        }
    `,
}

export const formatBPMRatio = (ratio) => {
    if (!ratio) {
        return 'unknown'
    }
    return `${(ratio * 100).toFixed(2)} %`
}

const RdwTable = ({
    data,
    order,
    setOrder,
}: {
    data: RdwListResponse
    order: string
    setOrder: (order: string) => void
}) => {
    const updateOrder = (field) => {
        if (!order.includes(field)) {
            setOrder(`-${field}`)
            return
        }
        if (order.includes('-')) {
            setOrder(field)
            return
        }
        setOrder('')
    }
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                active={order.includes('datum_eerste_tenaamstelling_in_nederland_dt')}
                                direction={
                                    order.includes('-datum_eerste_tenaamstelling_in_nederland_dt')
                                        ? 'desc'
                                        : 'asc'
                                }
                                onClick={() => updateOrder('datum_eerste_tenaamstelling_in_nederland_dt')}
                            >
                                Date of First Registration in NL
                            </TableSortLabel>
                        </TableCell>
                        <TableCell>Latest update</TableCell>
                        <TableCell>Plate</TableCell>
                        <TableCell>Make</TableCell>
                        <TableCell>Model</TableCell>
                        <TableCell>BPM</TableCell>
                        <TableCell>New value</TableCell>
                        <TableCell>BPM/New value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.results?.map((rdw) => (
                        <TableRow key={rdw.id}>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                {formatDateTime(rdw.datumEersteTenaamstellingInNederlandDt)}
                            </TableCell>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>
                                {formatDateTime(rdw.dateEdited)}
                            </TableCell>
                            <TableCell>
                                <Link to={`/rdw/search/${rdw.kenteken}/`}>{rdw.kenteken}</Link>
                            </TableCell>
                            <TableCell>{rdw.merk}</TableCell>
                            <TableCell>{rdw.handelsbenaming}</TableCell>
                            <TableCell>{rdw.brutoBpm}</TableCell>
                            <TableCell>{rdw.catalogusprijs}</TableCell>
                            <TableCell>{formatBPMRatio(rdw.newToBpmRatio)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const RESULTS_PER_PAGE = 10

const RdwList = () => {
    useRouteGuard('canReadVehicles', 'canViewRdw')

    const [order, setOrder] = useState('')
    const [page, setPage] = useState(1)
    const [valueRatio, setValueRatio] = useState(0)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search)
    const debouncedValueRatio = useDebounce(valueRatio)

    const searchParams = new URLSearchParams({
        search: debouncedSearch,
        offset: `${(page - 1) * RESULTS_PER_PAGE}`,
        limit: `${RESULTS_PER_PAGE}`,
        new_to_bpm_ratio__lte: `${debouncedValueRatio || ''}`,
        ordering: order,
    })
    const url = `/rdw/list/?${searchParams.toString()}`

    return (
        <S.TableView>
            <Stack flexDirection={{ sm: 'row' }} justifyContent={{ sm: 'space-between' }}>
                <Header>{'RDW data'}</Header>

                <S.SliderWrapper>
                    <p style={{ fontSize: 12 }}>
                        {valueRatio === 0
                            ? `BPM/value filter disabled`
                            : `BPM/value below ${(valueRatio * 100).toFixed(1)}%`}
                    </p>
                    <input
                        type="range"
                        min="0"
                        max="0.5"
                        step="0.005"
                        value={valueRatio}
                        onChange={(event) => setValueRatio(+event.target.value)}
                    />
                </S.SliderWrapper>

                <S.Search
                    placeholder="Search"
                    onChange={(e) => {
                        setSearch(e.target.value)
                        setPage(1)
                    }}
                />
            </Stack>

            <Get url={url}>
                {({ data }: { data: RdwListResponse }) => (
                    <>
                        <RdwTable data={data} order={order} setOrder={setOrder} />
                        <Pagination
                            sx={{ mt: 2 }}
                            count={Math.ceil(data.count / RESULTS_PER_PAGE)}
                            shape="rounded"
                            onChange={(_, _page) => setPage(_page)}
                            page={page}
                            color="primary"
                            siblingCount={3}
                        />
                    </>
                )}
            </Get>
        </S.TableView>
    )
}

export default RdwList
