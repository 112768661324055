import styled from 'styled-components'

const S = {
    FormHeader: styled.h1<{center?: boolean}>`
        font-weight: bold;
        font-size: 30px;
        margin-top: 42px;
        margin-bottom: 6px;
        text-align: ${({ center }) => (center ? 'center' : 'start')};
    `,
}

export default (props: { label: string }) => <S.FormHeader>{props.label}</S.FormHeader>
