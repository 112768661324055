import React from 'react'
import { styled } from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, Chip, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../users/authSlice'

const S = {
    Table: styled(Table)`
        &,
        & th,
        & td {
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-collapse: collapse;
        }
    `,
}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props
    return <IconButton {...other} />
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

const convertEmptyData = (data) => {
    return data || 'Not specified'
}

export const fields = [
    'finalBid',
    'auction',
    'lotNumber',
    'dateOfSale',
    'year',
    'vin',
    'condition',
    'engine',
    'mileage',
    'seller',
    'documents',
    'location',
    'primaryDamage',
    'secondaryDamage',
    'estimatedRetailValue',
    'estimatedRepairCost',
    'transmission',
    'bodyColor',
    'drive',
    'fuel',
    'keys',
    'notes',
]

export const camelToString = (str) => str.replace(/[A-Z]/g, (letter) => ` ${letter}`)

export const DetailTable = ({ item }) => (
    <S.Table size="small">
        <TableHead>
            <TableRow sx={{ background: 'lightgray' }}>
                <TableCell sx={{ width: 200, fontWeight: 700 }}>Field</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>Value</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {fields.map((field) => (
                <TableRow>
                    <TableCell sx={{ textTransform: 'capitalize' }}>{camelToString(field)}</TableCell>
                    <TableCell>{item[field]}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </S.Table>
)

const VinSearchItem = ({ item }) => {
    const user = useSelector(selectUser)

    const [expanded, setExpanded] = React.useState(false)
    const history = useHistory()

    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    return (
        <Card sx={{ maxWidth: 1000, p: 1 }} elevation={5}>
            <CardHeader
                title={item.vin}
                subheader={
                    <span>
                        <strong>Make: </strong>
                        {convertEmptyData(item.make)}
                        <strong>&nbsp; | &nbsp;</strong>
                        <strong>Model: </strong> {convertEmptyData(item.model)}
                    </span>
                }
            />
            <CardContent sx={{ py: 0 }}>
                <Box display="flex">
                    <Typography variant="body1" mr={2}>
                        Source:
                    </Typography>
                    <a href={item.source} target="__blank">
                        {item.source}
                    </a>
                </Box>
            </CardContent>
            <CardActions disableSpacing>
                {(user.canReadVehicles || user.isSuperuser) && (
                    <Chip
                        label={item.vehicle ? 'Go to the linked vehicle' : 'No case'}
                        color={item.vehicle ? 'primary' : 'error'}
                        variant="outlined"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => history.push(`/vehicles/${item.vehicle}`)}
                    />
                )}
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <DetailTable item={item} />
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default VinSearchItem
