import { VehicleType } from '../vehicles/types'
import { formatDate } from '../../utils/datetime'
import VehicleInfo from '../vehicles/components/VehicleInfo'
import { isDetailsField, isTelematicsField } from '../vehicles/fields'
import PdfPreview from './components/PdfPreview'
import PdfPage from './components/PdfPage'
import Heading from './components/Heading'
import VehicleImages from './components/VehicleImages'
import styled from 'styled-components'
import Header from '../../components/Header'
import { Table } from 'react-bootstrap'
import { camelToString, fields } from '../vin-search/VinSearchItem'
import GroupFields from '../../components/GroupFields'
import { rdwGroupFields } from '../rdw/fields'

const S = {
    Note: styled.p`
        font-size: 18px;
    `,
    Td: styled.td`
        width: 50%;
        text-transform: capitalize;
    `,
}

interface ReportProps {
    vehicle: VehicleType
}

const administrationFields = ['id', 'dateCreated', 'dateEdited', 'clientName']

const seizureFields = [
    'discoveredWithPlate',
    'datetimeSeized',
    'digitpolAtSeizure',
    'seizureOfficerName',
    'seizureOfficerContact',
    'foundContainer',
    'isPartOfTelematicsHit',
    'discoveredWithFalseVin',
    'discoveredWithFalsePlate',
    'discoveredWithVin',
    'seizedBy',
    'seizedLocationStreet',
    'seizedLocationPostcode',
    'seizedLocationCity',
    'seizedCountry',
]

const isSeizureField = (field: string) => seizureFields.includes(field)
const isAdministrationField = (field: string) => administrationFields.includes(field)
const isDetailsWithoutNote = (field: string) => isDetailsField(field) && field !== 'note'

const VehicleReport = ({ vehicle }: ReportProps) => {
    const today = formatDate(new Date())
    const body = `With reference to the vehicle bearing the Vehicle Identity Number (VIN) of ${vehicle.vinNumber}. 
    The following information is held by Digitpol as a result of a Stolen Signal and a Request to Search for the 
    mentioned vehicle. At the time of entry, a SIS warrant was active. This report was generated at ${today}`

    return (
        <PdfPreview>
            <PdfPage>
                <Heading fileName="Vehicle Report" vehicle={vehicle} />
                <p>{body}</p>
                <VehicleImages vehicle={vehicle} />
            </PdfPage>

            {vehicle.note && (
                <PdfPage>
                    <Header style={{ marginTop: '32px' }}>Intelligence</Header>
                    <S.Note>{vehicle.note}</S.Note>
                </PdfPage>
            )}

            <PdfPage>
                <VehicleInfo
                    header="Vehicle Details"
                    vehicle={vehicle}
                    fieldFilter={isDetailsWithoutNote}
                    compact
                />
            </PdfPage>

            <PdfPage>
                <VehicleInfo header="Seizure Report" vehicle={vehicle} fieldFilter={isSeizureField} compact />
                <VehicleInfo
                    header="Telematics / GPS Data"
                    vehicle={vehicle}
                    fieldFilter={isTelematicsField}
                    compact
                />
                <VehicleInfo
                    header="Admin Details"
                    vehicle={vehicle}
                    fieldFilter={isAdministrationField}
                    compact
                />
            </PdfPage>

            {vehicle.rdw && (
                <PdfPage>
                    <Header>RDW Data</Header>
                    <GroupFields settings={rdwGroupFields} data={vehicle.rdw} />
                </PdfPage>
            )}

            {vehicle.webData && (
                <PdfPage>
                    <div style={{ padding: 8 }}>
                        <Header>Web Hits</Header>
                        <Table size="sm">
                            <tbody>
                                {fields.map((field) => (
                                    <tr key={field}>
                                        <S.Td>{camelToString(field)}</S.Td>
                                        <S.Td>{vehicle.webData[field]}</S.Td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </PdfPage>
            )}
        </PdfPreview>
    )
}

export default VehicleReport
