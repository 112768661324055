import { useField } from 'formik'
import React from 'react'
import { Form, FormGroup } from 'react-bootstrap'
import { Accept, useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { uploadFile } from './FileUpload'

export const S = {
    Dropzone: styled.div`
        width: 100%;
        padding: 10px;
        height: auto;
        min-height: 180px;
        border: 1px dashed rgba(24, 28, 33, 0.15);
        outline: none;
        border-radius: 4px;
        color: rgba(78, 81, 85, 0.75);
        cursor: pointer;
    `,
    PreviewWrapper: styled.div`
        display: flex;
        flex-wrap: wrap;
    `,
    Thumb: styled.div`
        width: 223px;
        margin-top: 10px;
        margin-right: 10px;
        padding: 17px 13px;
        padding-bottom: 0;
        background: #ffffff;
        border: 1px solid #e8e9e9;
        box-sizing: border-box;
        border-radius: 4px;
        & img {
            width: 197px;
            height: 147px;
            min-height: 147px;
            object-fit: cover;
            object-position: center;
            border-radius: 4px;
        }
    `,
    RemoveButton: styled.div`
        width: calc(100% + 26px);
        margin: 0 -13px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #d32c3f;
        font-size: 14px;
        line-height: 16px;
        border-top: 1px solid #e8e9e9;
        cursor: pointer;
        &:hover {
            background-color: #f5f6f8;
        }
    `,
    Link: styled.a`
        padding-top: 10px;
        padding-bottom: 16px;
        text-decoration: none;
        font-size: 14px;
        color: #3068f4;
    `,
    FileName: styled.span`
        padding-top: 10px;
        padding-bottom: 16px;
        font-size: 14px;
        color: #667283;
    `,
}

interface ImageUploadProps {
    name: string
    label?: string
    placeholder?: string
    accept?: Accept
    maxSize?: number // in mb
    isMulti?: boolean
    maxFiles?: number
    dependField?: string
    isDisabled?: boolean
    isRequired?: boolean
    helperText?: string
}

const ImageUpload = ({
    name,
    label,
    helperText,
    accept,
    maxSize = 25,
    isMulti = false,
    placeholder = "Drag and Drop here or Browser files",
    isRequired,
    ...props
}: ImageUploadProps) => {
    const [field, meta, helper] = useField({ name })
    const { error, touched } = meta

    const onDrop = async (acceptedFiles) => {
        helper.setTouched(true)
        const uploads = await Promise.all(Array.from(acceptedFiles).map(uploadFile))
        helper.setValue((field.value || []).concat(uploads))
    }

    const handleRemove = (e, file?: { id: number }) => {
        e.stopPropagation()
        if (file) {
            helper.setValue(field.value.filter((e) => e.id !== file.id))
            return
        }
        helper.setValue([])
    }

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop,
        multiple: isMulti,
        accept,
        maxSize: maxSize * 1024 * 1024,
    })

    function replaceSizeUnit(match, p1) {
        // Convert from bytes to Mbs
        return ` ${+p1 / 1024 / 1024} Mbs`
    }

    return (
        <FormGroup {...props}>
            <Form.Label>
                {label}
                {isRequired && <span>&nbsp;*</span>}
            </Form.Label>

            <S.Dropzone {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p className="muted mb-1 text-center">Drop the file here ...</p>
                ) : (
                    <p className="muted text-center">{placeholder}</p>
                )}
                <S.PreviewWrapper>
                    {field.value && typeof field.value === 'string' ? (
                        <S.Thumb
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        >
                            <img src={field.value} alt="not-found" />
                            <S.Link href={field.value} className="text-cut" target="_blank">
                                {field.value}
                            </S.Link>
                            <S.RemoveButton onClick={handleRemove}>Remove</S.RemoveButton>
                        </S.Thumb>
                    ) : (
                        (field.value || []).map((file, i) => (
                            <S.Thumb
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                key={i}
                            >
                                <img
                                    src={file.file}
                                    alt={file.name}
                                    style={{ objectFit: 'contain', objectPosition: 'center' }}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null
                                        currentTarget.src = '/document.png'
                                        currentTarget.style.width = '80px'
                                    }}
                                />
                                <S.Link href={file.file} className="text-cut" target="_blank">
                                    {file.file?.split?.('__')?.slice(-1)}
                                </S.Link>

                                <S.RemoveButton onClick={(e) => handleRemove(e, file)}>Remove</S.RemoveButton>
                            </S.Thumb>
                        ))
                    )}
                </S.PreviewWrapper>
            </S.Dropzone>
            {fileRejections.map(({ errors }) => (
                <div>
                    {errors.map((e) => (
                        <small className="text-danger" key={e.code}>
                            {`${e.message.replace(/ (\d+) bytes/, replaceSizeUnit)}`}
                        </small>
                    ))}
                </div>
            ))}
            {!(touched && error) && <Form.Text muted>{helperText}</Form.Text>}
        </FormGroup>
    )
}

export default ImageUpload
