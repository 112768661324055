import env from '../constants/env'

export const removeSearch = (url = ''): string => url.split('?')[0]

export const getFileName = (url: string): string => {
    const mediaPath = env.isProduction
        ? '/smviu_django/'
        : '/media/'
    const withoutSearch = removeSearch(url)
    try {
        return withoutSearch.split(mediaPath)[1] || withoutSearch
    } catch (e) {
        return withoutSearch
    }
}

const imageTypes = ['.gif', '.jpg', '.jpeg', '.png'] // TODO extend these
export const isImage = (url: string) => imageTypes.filter((ext) => removeSearch(url).endsWith(ext)).length > 0
