import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import Get from '../../components/Get'
import { Link } from 'react-router-dom'
import { formatTimeAgo } from '../../utils/datetime'
import { useSelector } from 'react-redux'
import { selectUser } from '../users/authSlice'
import { Button, Stack, Typography, Box, Grid, Pagination, Autocomplete, TextField } from '@mui/material'
import api from '../../utils/api'
import { UserType } from '../users/types'
import { SearchesResponse } from './types'

const LIMIT = 10

const PreviousSearches = ({ users }: { users: UserType[] }) => {
    const user = useSelector(selectUser)
    const [page, setPage] = useState(1)
    const [allSearches, setAllSearches] = useState(false)
    const [userFilter, setUserFilter] = useState(user.id)
    const filter = {
        user: userFilter && `${userFilter}`,
        limit: LIMIT.toString(),
        offset: (LIMIT * (page - 1)).toString(),
    }
    const searchParams = new URLSearchParams(omitBy(filter, isEmpty))

    const changeSearchesType = () => {
        if (allSearches) {
            setUserFilter(user.id)
        } else {
            setUserFilter(undefined)
        }
        setAllSearches(!allSearches)
    }

    return (
        <>
            <Box display="flex" gap="40px" alignItems="center" mb={2}>
                <Typography variant="h5">{allSearches ? 'All searches' : 'My search history'}</Typography>
                {user.isSuperuser && (
                    <Button onClick={changeSearchesType} variant="contained" size="small">
                        {allSearches ? 'Show my searches' : 'Show all searches'}
                    </Button>
                )}
            </Box>
            {allSearches && (
                <Autocomplete
                    disablePortal
                    id="user-filter"
                    options={users}
                    sx={{ width: 300, mb: 2 }}
                    value={users.find((e) => e.id == userFilter)}
                    onChange={(e, value: UserType) => {
                        setUserFilter(value?.id)
                    }}
                    isOptionEqualToValue={(option, value) => option?.id == value?.id}
                    getOptionLabel={(user: UserType) => user.email}
                    renderInput={(params) => <TextField {...params} label="User" size="small" />}
                />
            )}
            <Get
                key={searchParams.toString()}
                url={`/vehicles/vin-searches/?${searchParams}`}
                defaultErrorMessage="Not found"
            >
                {({ data }: { data: SearchesResponse }) => (
                    <>
                        <p>Total of {data.count} searches</p>
                        {data?.results?.map((item) => (
                            <Box
                                sx={{
                                    padding: '4px',
                                    borderRadius: '3px',
                                    background: 'rgba(0,0,0,0.07)',
                                    margin: '4px 0',
                                }}
                            >
                                <Grid container alignItems="center" height={{ md: '24px', xs: 'auto' }}>
                                    <Grid item xs={12} sm={4} md={2}>
                                        {item.vehicleId && (user.canReadVehicles || user.isSuperuser) ? (
                                            <Link to={`/vehicles/${item.vehicleId}/`}>{item.vin}</Link>
                                        ) : (
                                            item.vin
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={8} md={10}>
                                        <Stack flexDirection="row">
                                            {item.make && (
                                                <Typography marginRight={4} variant="overline">
                                                    Make: <strong>{item.make}</strong>
                                                </Typography>
                                            )}
                                            {item.model && (
                                                <Typography variant="overline">
                                                    Model: <strong>{item.model}</strong>
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>
                                {allSearches && (
                                    <Typography variant="body1" color="blueviolet" mt={0.5} fontSize={14}>
                                        User: {item.user.email}
                                    </Typography>
                                )}
                                <Typography variant="caption" color="gray">
                                    {formatTimeAgo(item.dateCreated)}
                                </Typography>
                            </Box>
                        ))}
                        <Pagination
                            count={Math.ceil(data.count / LIMIT)}
                            shape="rounded"
                            onChange={(_, _page) => setPage(_page)}
                            page={page}
                            color="primary"
                            siblingCount={0}
                            sx={{ mt: 2, mb: 4 }}
                        />
                    </>
                )}
            </Get>
        </>
    )
}

export default PreviousSearches
