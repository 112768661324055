import { Typography, Box, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material'
import { formatDateTime } from '../../../utils/datetime'
import { RDW } from '../../vehicles/types'
import { fields } from '../fields'
import { parser } from '../../../components/GroupFields'
import * as Sentry from "@sentry/react";

const UpdateLog = ({ data }: { data: RDW }) => {
    return (
        <>
            <Typography variant="h6" fontSize="16px" fontWeight={700} marginBottom={0}>
                Update Log
            </Typography>
            <Box sx={{ height: 338, overflow: 'auto' }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow sx={{'& > th': {fontSize: '13px', fontWeight: 700}}}>
                            <TableCell>Field</TableCell>
                            <TableCell>Previous Value</TableCell>
                            <TableCell>New Value</TableCell>
                            <TableCell>Changed on</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data.history
                            .filter((v) => v.fieldName !== 'rawData')
                            .map((entry, i) => {
                                const field = fields.find((e) => e.field === entry.fieldName) || {
                                    label: entry.fieldName,
                                    type: 'string',
                                }
                                return (
                                    <TableRow
                                        key={i}
                                        sx={{
                                            background: i % 2 == 0 ? '#f5f5f5' : 'white',
                                            '& > td': { fontSize: '13px' },
                                        }}
                                    >
                                        <TableCell>{field.label}</TableCell>
                                        <TableCell>{parser(entry.previousValue, field.type)}</TableCell>
                                        <TableCell>{parser(entry.newValue, field.type)}</TableCell>
                                        <TableCell>{formatDateTime(entry.datetime)}</TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
                {!data.history?.length && <Typography sx={{ ml: 2, my: 2 }}>No entries</Typography>}
            </Box>
        </>
    )
}

export default Sentry.withErrorBoundary(UpdateLog, { fallback: <p className='error'></p> })
