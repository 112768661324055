import styled from 'styled-components'
import config from '../styles'
import { formatDate } from '../../../utils/datetime'

const S = {
    Footer: styled.footer`
      font-size: ${config.fontSizes.small}px;
      color: ${config.colors.gray};
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      @media screen {
          bottom: ${config.pdfPadding}px;
          left: ${config.pdfPadding}px;
          right: ${config.pdfPadding}px;
      }
    `,
    Primary: styled.span`
      color: ${config.colors.primary};
    `,
}

export default () => {
    const today = formatDate(new Date())
    return (
        <S.Footer>
            {today}
            <br />
            <S.Primary>DIGITPOL - For official use only</S.Primary>
            
            <p>Boogschutterstraat 1 | Apeldoorn 7323AE | The Netherlands</p>

            Phone:
            {' '}
            <a href="tel:+31558448040">+31558448040</a>
            {' '}
            |
            Email:
            {' '}
            <a href="mailto:info@digitpol.com">info@digitpol.com</a>
            {' '}
            |
            Web:
            {' '}
            <a href="https://digitpol.com">https://digitpol.com</a>

            <br />

            Company Registration Number. Netherlands 73676772

            <br />

            Investigation Bureau Licensed by The Netherlands Ministry of Justice: License POB1557
            <br />
        </S.Footer>
    )
}
