import styled from 'styled-components'
import isNil from 'lodash/isNil'
import { fieldToLabel, statusOptions } from '../formConfig'
import { formatDate, formatDateTime, formatDateTimeShort } from '../../../utils/datetime'
import ImageLink from '../../../components/ImageLink'
import { getFileName } from '../../../utils/url'
import { HistoryEntry, VehicleType } from '../types'
import { ReactNode } from 'react'
import { requestOptions } from '../views/RentalReportView'

const S = {
    FileLink: styled.a`
        display: block;
        max-width: 350px;
        word-wrap: anywhere;
    `,
    ImageLink: styled(ImageLink)`
        max-width: 200px;
        max-height: 130px;
        overflow: hidden;
        display: block;
        margin: 12px 0;
    `,
    GroupWrapper: styled.div`
        & > div:nth-child(2n) {
            background: #f5f5f5;
        }
    `,
    Header: styled.h1`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 0;
        padding-bottom: 4px;
        border-bottom: 1px solid black;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    `,
    DataLine: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 6px 12px;
        min-width: 0;
        & > div {
            min-width: 0;
            font-size: 13px;
        }
    `,
}

const getStatusLabel = (v: string) => (statusOptions.find(({ value }) => value === v) || {}).label
const boolParser = (v: any) => (v && v !== 'False' && v !== 'false' ? 'Yes' : 'No')
const dateParser = (v: string) => v && formatDate(v)
const dateTimeParser = (v: string) => v && formatDateTime(v)
const renderImages = (v) => (
    <div>
        {v.map(({ file }) => (
            <S.ImageLink src={file} key={file} />
        ))}
    </div>
)

const createHistoryRenderer =
    (field: string, parseValue: (v: any) => any) => (v, vehicle, histories?: HistoryEntry[]) => {
        const statusChanges = (histories || []).filter(({ fieldName }) => fieldName === field)

        const getRow = (value, date) => (
            <p key={date}>
                reported <b>{parseValue(value)}</b> on <b>{formatDateTimeShort(date)}</b>
            </p>
        )

        const initialValue =
            statusChanges.length > 0 ? statusChanges[statusChanges.length - 1].previousValue : vehicle.status

        return (
            <div>
                <p>
                    Current:
                    <b>{parseValue(v)}</b>
                </p>
                {statusChanges.map((change) => getRow(change.newValue, change.datetime))}
                {getRow(initialValue, vehicle.dateCreated)}
            </div>
        )
    }

const renderLinks = (v) => (
    <div>
        {v.map(({ file = '' }) => (
            <S.FileLink href={file} key={file}>
                {getFileName(file)}
            </S.FileLink>
        ))}
    </div>
)

const parsers = {
    dateProduction: dateParser,
    dateCreated: dateTimeParser,
    dateEdited: dateTimeParser,
    dateOfTheft: dateTimeParser,
    firstLocatedDate: dateTimeParser,
    lastLocatedDate: dateTimeParser,
    datetimeSeized: dateTimeParser,
    images: renderImages,
    files: renderLinks,
    theftReport: renderLinks,
    seizureReports: renderLinks,
    recoveryReports: renderLinks,
    vehicleRegistrationPapers: renderLinks,
    insuranceDocs: renderLinks,
    authorizationToTowDocs: renderLinks,
    processTelematics: boolParser,
    registeredByApi: boolParser,
    digitpolAtSeizure: boolParser,
    isFoundInContainer: boolParser,
    isPartOfTelematicsHit: boolParser,
    wamInsured: boolParser,
    orderToRetrieve: createHistoryRenderer('orderToRetrieve', boolParser),
    status: createHistoryRenderer('status', getStatusLabel),
    scmClass: boolParser,
    ValidDate: dateParser,
    LastControlDate: dateParser,
    ConfirmationDate: dateParser,
    ModificationDate: dateParser,
    createdAt: dateTimeParser,
    powerOfAttorney: renderLinks,
    rentalAgreement: renderLinks,
    renterIdDocs: renderLinks,
    rentalAgreementCancellationDocs: renderLinks,
    requestType: (v) => requestOptions.find((e) => e.value == v)?.label || '',
    recoveredDate: dateParser,
}

const parseValue = (key, value, vehicle, histories) =>
    parsers[key]?.(value, vehicle, histories) || `${value ?? ''}`

interface VehicleInfoProps {
    vehicle: VehicleType
    fieldFilter?: (key: string) => boolean
    fieldSorting?: (a: { key: string }, b: { key: string }) => number
    compact?: boolean
    filterEmptyFields?: boolean
    header?: string
    actionsRender?: () => ReactNode
    histories?: HistoryEntry[]
}

const hasValue = (v) => !isNil(v) && v !== ''

const getFields = (
    vehicle: VehicleType,
    fieldFilter: (v: string) => boolean
): { key: string; value: any }[] =>
    Object.keys(vehicle)
        .filter(fieldFilter)
        .map((key) => ({ key, value: vehicle[key] }))

const VehicleInfo = ({
    vehicle,
    fieldFilter = (_key) => true,
    fieldSorting = (a, b) => 0,
    header = null,
    filterEmptyFields = false,
    actionsRender,
    histories,
}: VehicleInfoProps) => {
    const fields = getFields(vehicle, fieldFilter)
        .filter(({ value }) => !filterEmptyFields || hasValue(value))
        .sort(fieldSorting)

    return (
        <S.GroupWrapper>
            {header && (
                <S.Header>
                    {header}
                    {actionsRender?.()}
                </S.Header>
            )}

            {fields.map(({ key, value }) => (
                <S.DataLine>
                    <div>{fieldToLabel(key) || key}</div>
                    <div>{parseValue(key, value, vehicle, histories)}</div>
                </S.DataLine>
            ))}
        </S.GroupWrapper>
    )
}

export default VehicleInfo
