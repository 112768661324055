import React from 'react'
import styled from 'styled-components'
import {Note} from "../../vehicles/types";
import {formatTimeAgo} from "../../../utils/datetime";
import userText from "../userText";
import ImageLink from "../../../components/ImageLink";
import {getFileName} from "../../../utils/url";
import { format } from 'date-fns';

interface IsSentProps {
    isSent?: boolean
}

interface NoteProps {
    note: Partial<Note>
}

const getChatBackgroundColor = ({ isSent }: IsSentProps) => (!isSent
        ? 'rgba(120, 144, 156, 0.1)'
        : 'rgba(120, 144, 156, 0.05)'
);

const getBorderRadius = ({ isSent }: IsSentProps) => (!isSent
        ? '0px 5px 5px 5px'
        : '5px 0px 5px 5px'
)

const getJustifyText = ({ isSent }: IsSentProps) => (!isSent
    ? 'flex-start'
    : 'flex-end')

const getFlexDirection = ({ isSent }: IsSentProps) => (!isSent
    ? 'row'
    : 'row-reverse')

const S = {
    Note: styled.div<IsSentProps>`
      min-height: 45px; // Fix bug can't scroll bottom on safari
      flex-direction: ${getFlexDirection};
      display: flex;
      align-items: start;
      margin-bottom: 8px;
    `,
    NoteInner: styled.div<IsSentProps>`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: ${getJustifyText};
      margin-left: 8px;
      margin-right: 8px;
      white-space: pre-line;
    `,
    FileLink: styled.a`
      display: block;
      max-width: 350px;
      word-wrap: anywhere;
    `,
    ImageList: styled.div`
        display: flex;
        flex-wrap: wrap;
        margin: -6px;
    `,
    From: styled.p`
        font-weight: bold;
    `,
    ImageLink: styled(ImageLink)`
      max-width: 200px;
      max-height: 130px;
      overflow: hidden;
      display: block;
      margin: 6px;
    `,
    TextBox: styled.div<IsSentProps>`
      background-color: ${getChatBackgroundColor};
      color: black;
      padding: 4px 8px;
      font-weight: 300;
      font-size: 12px;
      border-radius: ${getBorderRadius};
      width: 100%;
      white-space: pre-line;
    `,
    Date: styled.div<IsSentProps>`
      color: gray;
      font-size: 10px;
      justify-content: ${getJustifyText};
      display: flex;
      flex-grow: 1;
      padding-top: 4px;
      width: 100%;
    `,
}

const transformText = (note: Partial<Note>) => {
    if (!note.byUser && note.text === 'Stolen') {
        return `Stolen on ${format(new Date(note.dateCreated), 'MMM dd, yyyy')}`
    }
    if (!note.byUser && note.text.startsWith('Seized__')) {
        let text = `Seized on ${format(new Date(note.dateCreated), 'MMM dd, yyyy')}`
        if (note.text.split("__")[1]) {
            text += `\nPolice Number: ${note.text.split("__")[1]}`
        }
        return text
    }
    return note.text
}

const NoteView = ({ note }: NoteProps) => {
    const isSent = false
    return (
        <S.Note isSent={isSent}>
            <S.NoteInner isSent={isSent}>
                <S.TextBox isSent={isSent}>
                    <S.From>{userText(note.byUser, note.userDeleted)}</S.From>
                    {transformText(note)}
                    {note.images?.length > 0 && (
                        <div>
                            <p style={{ marginTop: 12, marginBottom: 0 }}>Images:</p>
                            <S.ImageList>
                                {note.images.map(({ file }) => (
                                    <S.ImageLink src={file} key={file} />
                                ))}
                            </S.ImageList>
                        </div>
                    )}
                    {note.files?.length > 0 && <p>Files:</p>}
                    {note.files?.length > 0 && note.files.map(({ file }) => (
                        <S.FileLink href={file} key={file}>{getFileName(file)}</S.FileLink>
                    ))}
                </S.TextBox>
                <S.Date isSent={isSent}>
                    {formatTimeAgo(note.dateCreated)}
                </S.Date>
            </S.NoteInner>
        </S.Note>
    )
}

export default NoteView
