import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

const appSlice = createSlice({
    name: 'AppSlice',
    initialState: {
        pageLoading: false,
    },
    reducers: {
        setIsPageLoading(state, action: PayloadAction<boolean>) {
            state.pageLoading = action.payload
        }
    }
})

export const { setIsPageLoading } = appSlice.actions

export const pageLoadingSelector = (state: RootState) => state.app.pageLoading

export default appSlice.reducer
