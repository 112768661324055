import {Alert, Box, Button, Divider, Grid, Paper, Typography} from "@mui/material";
import {formatDateTime} from "../../../utils/datetime";
import GroupFields from "../../../components/GroupFields";
import {rdwGroupFields} from "../fields";
import Notes from "./Notes";
import PreviousSearches from "./PreviousSearches";
import UpdateLog from "./UpdateLog";
import {RDW} from "../../vehicles/types";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../users/authSlice";
import api from "../../../utils/api";
import {showErrorNotification, showNotification} from "../../notifications/notificationsSlice";
import {useHistory} from "react-router-dom";

const TrackUpdates = ({ data, refetch }: { data: RDW; refetch: () => void }) => {
    const dispatch = useDispatch()
    const currentUser = useSelector(selectUser)
    const subscribed = data.subscribedUpdateUsers.includes(currentUser.id)

    const handleTrackButton = async () => {
        if (subscribed) {
            const { error } = await api.delete(`/rdw/${data.kenteken}/subscribe-update/`)
            if (error) {
                return dispatch(showErrorNotification(error || 'Something went wrong'))
            }
            dispatch(
                showNotification({
                    body: 'Unsubscribed to track RDW updates',
                    type: 'success',
                    displayDuration: 3000,
                })
            )
            refetch()
            return
        }

        const { error } = await api.post(`/rdw/${data.kenteken}/subscribe-update/`)
        if (error) {
            return dispatch(showErrorNotification(error || 'Something went wrong'))
        }
        dispatch(
            showNotification({
                body: 'Subscribed to track RDW updates',
                type: 'success',
                displayDuration: 3000,
            })
        )
        refetch()
    }

    return (
        <Button variant="outlined" size="small" onClick={handleTrackButton}>
            {subscribed ? 'Untrack updates' : 'Track RDW updates'}
        </Button>
    )
}


const RDWTables = ({ data, refetch }: { data: RDW; refetch: () => void }) => {
    const history = useHistory()
    const handleRequestWOK = (data: RDW) => {
        const url = `/wok-request?plate=${data.kenteken}&make=${data.merk}&model=${data.handelsbenaming}`
        history.push(url)
    }
    const refetchRdwData = () => {
        history.push(history.location.pathname + '?refetch=true')
    }
    return (
        <>
            <Alert variant="standard" severity="info">
                <Typography variant="caption" sx={{mr: 4, display: "block", fontSize: '13px'}}>
                    <Box>License plate: {data.kenteken}</Box>
                    <TrackUpdates data={data} refetch={refetch} />
                </Typography>
                <Typography variant="caption" sx={{mt: 1, display: "block", fontSize: '13px'}}>
                    Data retrieved from RDW at: {formatDateTime(data.dateEdited)}
                </Typography>
                <Typography variant="caption" sx={{mt: 1, display: "block", fontSize: '13px'}}>
                    WOK: {data?.wokRequested ? (
                    "Requested"
                ) : (
                    <Button
                        size="small"
                        onClick={() => handleRequestWOK(data)}
                        variant="outlined"
                    >
                        Request WOK
                    </Button>
                )}
                </Typography>

                <Typography variant="caption" sx={{mt: 1, display: "block", fontSize: '13px'}}>
                    <Button
                        size="small"
                        onClick={() => refetchRdwData()}
                        variant="outlined"
                    >Refetch RDW data</Button>
                </Typography>
            </Alert>

            <Grid container spacing={2} marginTop={1} alignItems="stretch">
                <Grid item xs={12} lg={6}>
                    <Paper sx={{ p: 2, pb: 4, height: "100%" }}>
                        <GroupFields settings={rdwGroupFields} data={data} />
                    </Paper>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Paper sx={{ p: 2, paddingTop: '9px', height: "100%" }}>
                        <Notes data={data} refetch={refetch} />

                        <Divider sx={{ width: '100%', my: 2, background: 'black' }} />

                        <PreviousSearches plate={data.kenteken} />

                        <Divider sx={{ width: '100%', my: 2, background: 'black' }} />

                        <UpdateLog data={data} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default RDWTables
