import styled from '@emotion/styled'
import React from 'react'
import { formatDateTime } from '../utils/datetime'

export type DataType = 'string' | 'date' | 'boolean' | 'float'

export interface Field {
    field: string
    label: string
    type?: DataType
    defaultValue?: any
}

export interface GroupField {
    group: string
    fields: Field[]
}

interface Props {
    settings: GroupField[]
    data: any
}

const S = {
    GroupWrapper: styled.div<{index: number}>`
        margin-top: ${({index}) => index !== 0 ? '32px' : 0};
        & > div:nth-child(2n) {
            background: #f5f5f5;
        }
    `,
    Header: styled.h1`
        margin: 16px 0;
        padding-bottom: 4px;
        border-bottom: 1px solid black;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    `,
    DataLine: styled.div`
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 6px 12px;
        min-width: 0;
        & > div {
            min-width: 0;
            font-size: 13px;
        }
    `
}

export const parser = (v: any, type: DataType) => {
    if (type === 'boolean') {
        return v ? 'Yes' : 'No'
    }
    if (v === 'Ja') {
        return 'Yes'
    }
    if (v === 'Nee') {
        return 'No'
    }
    if (type === 'date') {
        try {
            const newValue = formatDateTime(v)
            if (newValue !== 'Invalid Date') {
                return newValue
            }
        } catch (e) {
            return v
        }
    }

    if (type === 'float') {
        return parseFloat(v).toFixed(4)
    }
    return v
}

const GroupFields = ({settings, data}: Props) => {
  return (
      <>
        {
            settings.map((groupSetting, index) => (
                <S.GroupWrapper key={groupSetting.group} index={index}>
                    <S.Header>{groupSetting.group}</S.Header>
                    {groupSetting.fields.map((fieldSetting) => (
                        <S.DataLine>
                            <div>{fieldSetting.label}</div>
                            <div>{parser(data[fieldSetting.field], fieldSetting.type || 'string')}</div>
                        </S.DataLine>
                    ))}
                </S.GroupWrapper>
            ))
        }
      </>
  )
}

export default GroupFields
