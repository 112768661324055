import { Alert, Box, Button, Paper, TextField } from '@mui/material'
import { Container } from '@mui/system'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { setIsPageLoading } from '../../../appSlice'
import ImageUpload from '../../../components/ImageUpload'
import useRouteGuard from '../../../hooks/useRouteGuard'
import api from '../../../utils/api'
import { showErrorNotification, showNotification } from '../../notifications/notificationsSlice'

const WOKRequestForm = ({ make, model, plate }: { make: string; model: string; plate: string }) => {
    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <Formik
            initialValues={{
                plate,
                model,
                make,
                chasiNumber: '',
                theftLocationStreet: '',
                theftLocationPostcode: '',
                theftLocationCity: '',
                theftLocationCountry: '',
                notes: '',
                images: [],
            }}
            enableReinitialize
            onSubmit={async (values) => {
                values.images = values.images.map((image) => image.id)
                const { error } = await api.post(`/wok-request/`, values)
                if (error) {
                    return dispatch(showErrorNotification(error || 'Something went wrong'))
                }
                dispatch(showNotification({
                    type: 'success',
                    body: 'Requested WOK successfully',
                }))
                history.push('/rdw') // Redirect to RDW list page.
            }}
        >
            {({ values, handleChange }) => (
                <Form>
                    <TextField
                        fullWidth
                        id="model"
                        name="model"
                        label="Model"
                        size="small"
                        value={values.model}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        id="make"
                        name="make"
                        label="Make"
                        size="small"
                        value={values.make}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        id="chasiNumber"
                        name="chasiNumber"
                        label="Chasi number"
                        size="small"
                        value={values.chasiNumber}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        id="theftLocationStreet"
                        name="theftLocationStreet"
                        label="Theft Street"
                        size="small"
                        value={values.theftLocationStreet}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        id="theftLocationPostcode"
                        name="theftLocationPostcode"
                        label="Theft Postcode"
                        size="small"
                        value={values.theftLocationPostcode}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        id="theftLocationCity"
                        name="theftLocationCity"
                        label="Theft City"
                        size="small"
                        value={values.theftLocationCity}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        id="theftLocationCountry"
                        name="theftLocationCountry"
                        label="Theft Country"
                        size="small"
                        value={values.theftLocationCountry}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        id="notes"
                        name="notes"
                        label="Notes"
                        size="small"
                        value={values.notes}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                        multiline
                        rows={5}
                    />
                    <ImageUpload
                        name="images"
                        label="Images"
                        isMulti
                        placeholder="Drag and Drop here or Browser files"
                    />
                    <Button color="primary" variant="contained" type="submit">
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    )
}

const WOKRequestView = () => {
    useRouteGuard('canReportWok', 'canViewWokRequest')

    const dispatch = useDispatch()
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [search, setSearch] = useState('')
    const [rdwData, setRdwData] = useState(undefined)
    const [fetched, setFetched] = useState(false)

    useEffect(() => {
        if (params.get('plate')) {
            setSearch(params.get('plate'))
            setRdwData({merk: params.get('model'), handelsbenaming: params.get('make')})
            setFetched(true)
        }
    }, [params.get('plate')])

    useEffect(() => {
        if (fetched && !params.get('plate')) {
            setFetched(false)
        }
    }, [search])

    const searchRDW = async () => {
        dispatch(setIsPageLoading(true))
        const { data } = await api.get(`/rdw/${search}/`)
        setFetched(true)
        setRdwData(data)
        dispatch(setIsPageLoading(false))
    }

    return (
        <Container maxWidth="md" sx={{ p: 2 }}>
            <Paper sx={{ p: 2 }}>
                <Box display="flex" alignItems="center" gap={2} marginBottom={2}>
                    <TextField
                        variant="outlined"
                        size="small"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        label="Fill plate"
                    />
                    <Button variant="contained" size="medium" onClick={searchRDW} disabled={!search}>
                        Search
                    </Button>
                </Box>
                {fetched && (
                    <Alert severity={rdwData ? 'success' : 'error'} sx={{ mb: 3 }}>
                        {rdwData ? 'Car data found in RDW' : 'Car data not foud in RDW'}
                    </Alert>
                )}

                {rdwData?.wokRequested && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                    This car is already WOK requested
                </Alert>
                )}

                {fetched && !rdwData?.wokRequested && (
                    <WOKRequestForm
                        make={rdwData?.merk || ''}
                        model={rdwData?.handelsbenaming || ''}
                        plate={search}
                    />
                )}
            </Paper>
        </Container>
    )
}

export default WOKRequestView
