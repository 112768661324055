import React from 'react'
import {
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import api from '../../../utils/api'
import { useDispatch } from 'react-redux'
import { showErrorNotification, showNotification } from '../../notifications/notificationsSlice'

const fields = [
    { name: 'Dashboard', field: 'canViewDashboard' },
    { name: 'Plate Search', field: 'canViewPlateSearch' },
    { name: 'RDW database', field: 'canViewRdw' },
    { name: 'Database', field: 'canViewDatabase' },
    { name: 'Thefts Per Street', field: 'canViewThefts' },
    { name: 'New seizure', field: 'canViewNewSeizure' },
    { name: 'Report for search', field: 'canViewReportForSearch' },
    { name: 'WOK request', field: 'canViewWokRequest' },
    { name: 'Security', field: 'canViewSecurity' },
    { name: 'Repatriation', field: 'canViewRepatriation' },
    { name: 'Rental Report', field: 'canViewRentalReport' },
    { name: 'Trackers', field: 'canViewTrackers' },
    { name: 'Webhits', field: 'canViewWebhits' },
    { name: 'Containers', field: 'canViewContainers' },
]

const ManagePageRoles = ({ user }) => {
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            canViewDashboard: user.canViewDashboard,
            canViewRdw: user.canViewRdw,
            canViewPlateSearch: user.canViewPlateSearch,
            canViewDatabase: user.canViewDatabase,
            canViewThefts: user.canViewThefts,
            canViewNewSeizure: user.canViewNewSeizure,
            canViewReportForSearch: user.canViewReportForSearch,
            canViewWokRequest: user.canViewWokRequest,
            canViewSecurity: user.canViewSecurity,
            canViewRepatriation: user.canViewRepatriation,
            canViewRentalReport: user.canViewRentalReport,
            canViewTrackers: user.canViewTrackers,
            canViewWebhits: user.canViewWebhits,
            canViewContainers: user.canViewContainers,
        },
        onSubmit: async (values) => {
            const response = await api.patch(`/users/${user.id}/`, values)
            if (response.code > 300) {
                dispatch(showErrorNotification(response.error || 'Something went wrong'))
            } else {
                dispatch(
                    showNotification({
                        body: 'Updated successfully',
                        type: 'success',
                    })
                )
            }
        },
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box sx={{ margin: 1 }}>
                <Typography variant="body1" fontWeight={700} gutterBottom component="div">
                    View page roles
                </Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {fields.map((field) => (
                                <TableCell key={field.field}>{field.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {fields.map((field) => (
                                <TableCell key={field.field}>
                                    <Checkbox
                                        name={field.field}
                                        checked={!!formik.values[field.field]}
                                        value={!!formik.values[field.field]}
                                        onChange={formik.handleChange}
                                        disabled
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </Box>
        </form>
    )
}

export default ManagePageRoles
