import { useEffect, useState } from 'react'
import FormRender from '@data-driven-forms/react-form-renderer/dist/cjs/form-renderer'
import FormTemplate from '@data-driven-forms/pf4-component-mapper/dist/cjs/form-template'
import {
    Box,
    Button,
    Dialog,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import Get from '../../components/Get'
import { OrganizationType, UserType } from './types'
import { componentMapper, validatorMapper } from '../vehicles/formConfig'
import api from '../../utils/api'
import { showErrorNotification } from '../notifications/notificationsSlice'
import Header from '../../components/Header'
import UserPermissionRow from './components/UserPermissionRow'

export const UserForm = (props: {
    user?: UserType
    onSubmit?: () => void
    organizations?: OrganizationType[]
}) => {
    const dispatch = useDispatch()
    const schema = {
        fields: [
            {
                component: 'text-field',
                type: 'email',
                name: 'email',
                label: 'email',
            },
            { component: 'text-field', name: 'clientName', label: 'Client name' },
            {
                component: 'select',
                label: 'Organization',
                name: 'organizationId',
                simpleValue: true,
                options: props.organizations,
            },
            { component: 'checkbox', name: 'canReadVehicles', label: 'Can Read Vehicles' },
            { component: 'checkbox', name: 'canEditVehicles', label: 'Can Edit Vehicles' },
            { component: 'checkbox', name: 'canReportMissing', label: 'Can Request Search' },
            { component: 'checkbox', name: 'canReportSeizure', label: 'Can Report Seizure' },
            { component: 'checkbox', name: 'canReportWok', label: 'Can Report WOK' },
            { component: 'checkbox', name: 'canReportRental', label: 'Can Report Rental' },
            { component: 'checkbox', name: 'canSearchVin', label: 'Can Search VIN' },
            { component: 'checkbox', name: 'canRegisterContainer', label: 'Can Register Container' },
            { component: 'header', name: 'View Page Roles', label: 'View Page Roles' },
            {
                component: 'checkbox',
                name: 'canViewDashboard',
                label: 'Can View Dashboard',
                initialValue: true,
            },
            { component: 'checkbox', name: 'canViewRdw', label: 'Can View RDW', initialValue: true },
            {
                component: 'checkbox',
                name: 'canViewPlateSearch',
                label: 'Can View Plate Search',
                initialValue: true,
            },
            {
                component: 'checkbox',
                name: 'canViewDatabase',
                label: 'Can View Database',
                initialValue: true,
            },
            { component: 'checkbox', name: 'canViewThefts', label: 'Can View Thefts', initialValue: true },
            {
                component: 'checkbox',
                name: 'canViewNewSeizure',
                label: 'Can View New Seizure',
                initialValue: true,
            },
            {
                component: 'checkbox',
                name: 'canViewReportForSearch',
                label: 'Can View Report For Search',
                initialValue: true,
            },
            {
                component: 'checkbox',
                name: 'canViewWokRequest',
                label: 'Can View Wok Request',
                initialValue: true,
            },
            {
                component: 'checkbox',
                name: 'canViewSecurity',
                label: 'Can View Security',
                initialValue: true,
            },
            {
                component: 'checkbox',
                name: 'canViewRepatriation',
                label: 'Can View Repatriation',
                initialValue: true,
            },
            {
                component: 'checkbox',
                name: 'canViewRentalReport',
                label: 'Can View Rental Reports',
                initialValue: true,
            },
            {
                component: 'checkbox',
                name: 'canViewTrackers',
                label: 'Can View Trackers',
                initialValue: true,
            },
            {
                component: 'checkbox',
                name: 'canViewWebhits',
                label: 'Can View Web HITS',
                initialValue: true,
            },
            {
                component: 'checkbox',
                name: 'canViewContainers',
                label: 'Can View Containers',
                initialValue: true,
            },
        ],
    }

    const onSubmit = async (data) => {
        const response = await (props.user?.id
            ? api.patch(`/users/${props.user.id}/`, data)
            : api.post('/users/', data))
        if (response.code > 300) {
            dispatch(showErrorNotification(response.error || 'Something went wrong'))
        } else {
            props?.onSubmit()
        }
    }

    return (
        <FormRender
            initialValues={props.user || {}}
            schema={schema}
            onSubmit={onSubmit}
            FormTemplate={FormTemplate}
            validatorMapper={validatorMapper}
            componentMapper={componentMapper}
        />
    )
}

const InviteUser = (props: { onSubmit: () => void; organizations: OrganizationType[] }) => {
    const [isInviting, setIsInviting] = useState(false)
    const hide = () => setIsInviting(false)
    const onSubmit = () => {
        hide()
        props.onSubmit()
    }

    return (
        <>
            <Button onClick={() => setIsInviting(true)} style={{ marginBottom: 24 }} variant="contained">
                Invite new user
            </Button>

            <Dialog open={isInviting} onClose={hide} maxWidth="lg" fullWidth>
                <Box p="20px">
                    <UserForm onSubmit={onSubmit} organizations={props.organizations} />
                </Box>
            </Dialog>
        </>
    )
}

const ManageUserPermissions = () => {
    const [organizations, setOrganizations] = useState([])
    useEffect(() => {
        async function getOrganizations() {
            const resp = await api.get('/organizations/')
            setOrganizations(
                resp.data.results.map((e) => ({
                    label: e.name,
                    value: e.id,
                }))
            )
        }
        getOrganizations()
    }, [])

    return (
        <Get url="/users/?limit=999">
            {({ data, refetch }: { data: { results: UserType[] }; refetch: () => void }) => (
                <>
                    <hr style={{ marginBottom: 24 }} />
                    <Header>User management</Header>

                    <InviteUser onSubmit={refetch} organizations={organizations} />

                    <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Last Login</TableCell>
                                    <TableCell>Can Edit Vehicles</TableCell>
                                    <TableCell>Can Read Vehicles</TableCell>
                                    <TableCell>Can Request Search</TableCell>
                                    <TableCell>Can Report Search</TableCell>
                                    <TableCell>Can Report WOK</TableCell>
                                    <TableCell>Can Report Rental</TableCell>
                                    <TableCell>Can Search VIN</TableCell>
                                    <TableCell>Can Register Container</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.results
                                    .filter((user) => !user.isSuperuser)
                                    .map((user) => (
                                        <UserPermissionRow
                                            user={user}
                                            key={user.id}
                                            onSubmit={refetch}
                                            organizations={organizations}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Get>
    )
}

export default ManageUserPermissions
