import { Box, Button, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import { TrackerLocation } from './types'
import { showErrorNotification, showNotification } from '../notifications/notificationsSlice'
import { useDispatch } from 'react-redux'
import api from '../../utils/api'

const TrackerForm = ({
    initialData,
    close,
    refetch,
}: {
    initialData?: TrackerLocation
    close?: () => void
    refetch?: () => void
}) => {
    const dispatch = useDispatch()

    const deleteLocations = async () => {
        if (confirm('Are you sure?') == false) {
            return
        }
        const { error } = await api.delete(`/map-trackers/${initialData.imei}/locations/`)
        if (error) {
            return dispatch(showErrorNotification(error || 'Something went wrong'))
        }

        close?.()
        window.location.reload()
    }

    const deleteDevice = async () => {
        if (confirm('Are you sure?') == false) {
            return
        }
        const { error } = await api.delete(`/map-trackers/${initialData.imei}/`)
        if (error) {
            return dispatch(showErrorNotification(error || 'Something went wrong'))
        }

        close?.()
        window.location.reload()
    }

    return (
        <Formik
            initialValues={
                initialData || {
                    imei: '',
                    name: '',
                    vehicle: '',
                }
            }
            enableReinitialize
            onSubmit={async (values) => {
                if (!initialData) {
                    const { error } = await api.post(`/map-trackers/`, values)
                    if (typeof error === 'string' && error.toString().search(/already exists/) >= 0) {
                        return dispatch(showErrorNotification('This plate is already assigned to a tracker'))
                    }
                    if (error) {
                        return dispatch(showErrorNotification(error || 'Something went wrong'))
                    }
                    dispatch(
                        showNotification({
                            type: 'success',
                            body: 'Create tracker successfully',
                        })
                    )
                    refetch()
                } else {
                    const { error } = await api.patch(`/map-trackers/${initialData.imei}/`, values)
                    if (error) {
                        return dispatch(showErrorNotification(error || 'Something went wrong'))
                    }
                    dispatch(
                        showNotification({
                            type: 'success',
                            body: 'Update tracker successfully',
                        })
                    )
                    refetch()
                }
                close()
            }}
        >
            {({ values, handleChange }) => (
                <Form>
                    <TextField
                        fullWidth
                        id="imei"
                        name="imei"
                        label="IMEI"
                        size="small"
                        value={values.imei}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                        disabled={!!initialData}
                    />
                    <TextField
                        fullWidth
                        id="name"
                        name="name"
                        label="Display name"
                        size="small"
                        value={values.name}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        id="vehicle"
                        name="vehicle"
                        label="Assign device to vehicle"
                        size="small"
                        value={values.vehicle}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />

                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex" gap={2}>
                            <Button color="info" variant="contained" size="small" type="submit">
                                Submit
                            </Button>
                            <Button color="info" variant="contained" size="small" onClick={close}>
                                Close
                            </Button>
                        </Box>
                        <Box display="flex" gap={2}>
                            <Button variant="outlined" size="small" color="error" onClick={deleteLocations}>
                                Clear locations
                            </Button>
                            <Button size="small" variant="outlined" color="error" onClick={deleteDevice}>
                                Remove device
                            </Button>
                        </Box>
                    </Box>
                </Form>
            )}
        </Formik>
    )
}

export default TrackerForm
