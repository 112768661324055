import {HistoryEntry, VehicleType} from "./types";
import {formatDateTimeShort} from "../../utils/datetime";

export default (vehicle: VehicleType): string => {
    const history = vehicle.history || [];
    const statusChanges = history.filter((v: HistoryEntry) => v.fieldName === 'status');
    const setsToStolen = statusChanges.find((v: HistoryEntry) => v.newValue === 'stolen');
    const setToNotStolen = statusChanges.find((v: HistoryEntry) => v.previousValue === 'stolen');
    // use created date as stolen if the vehicle was initially registered as stolen. In this case it wont be in history
    const wasStolen = setToNotStolen || vehicle.status === 'stolen';
    const stolenDate = setsToStolen?.datetime || (wasStolen && vehicle.dateCreated);
    return [
        stolenDate && `Reported stolen on ${formatDateTimeShort(stolenDate)}`,
        setToNotStolen?.datetime && `Reported not stolen on ${formatDateTimeShort(setToNotStolen?.datetime)}`,
    ].filter(v => v).join(', ')
}
