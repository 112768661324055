import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Get from '../../../components/Get'
import Header from '../../../components/Header'
import useInterval from '../../../hooks/useInterval'
import useRouteGuard from '../../../hooks/useRouteGuard'

interface Row {
    theftStreetStripped: string
    street: string
    city: string
    count: number
}

interface Response {
    results: Row[]
}

const S = {
    Heading: styled.div`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    `,
    TableView: styled.div`
        padding: 24px;
    `,
}

const VehicleTable = ({ data }: { data: Response }) => (
    <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Street - City</TableCell>
                    <TableCell>Thefts</TableCell>
                </TableRow>
            </TableHead>
            <tbody>
                {data?.results?.map((row: Row) => (
                    <TableRow key={row.theftStreetStripped}>
                        <TableCell>
                            <Link to={`/thefts-per-street/${row.theftStreetStripped}/${row.street}`}>
                                {row.street} - {row.city}
                            </Link>
                        </TableCell>
                        <TableCell>{row.count}</TableCell>
                    </TableRow>
                ))}
            </tbody>
        </Table>
    </TableContainer>
)

const UpdatedAt = ({ date }: { date: Date }) => {
    const now = useInterval()
    const seconds = Math.round((now.getTime() - date.getTime()) / 1000)
    return <h5 style={{ marginBottom: 10 }}>updated {seconds + 1} seconds ago</h5>
}

const TableView = () => {
    useRouteGuard('canReadVehicles', 'canViewThefts')
    return (
        <S.TableView>
            <Header>Thefts Per Street</Header>

            <Get url="/vehicles/per-street/" interval={30}>
                {({ data }: { data: Response }) => (
                    <>
                        <UpdatedAt date={new Date()} />
                        <VehicleTable data={data} />
                    </>
                )}
            </Get>
        </S.TableView>
    )
}

export default TableView
